export const API_ROOT = window.__API_URL__;
export const METABASE_API_ROOT = 'https://metabase-api.perxtech.io';
export const PROJECT_NAME = 'PERX_APP';
const CONTAINER_NAME = 'App';

export const SIGNIN_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNIN_USER`;
export const SIGNIN_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNIN_USER_SUCCESS`;
export const SIGNIN_USER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNIN_USER_FAIL`;

export const TOKEN_SIGNIN = `${PROJECT_NAME}/${CONTAINER_NAME}/TOKEN_SIGNIN`;
export const TOKEN_SIGNIN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/TOKEN_SIGNIN_SUCCESS`;
export const TOKEN_SIGNIN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/TOKEN_SIGNIN_FAIL`;

export const OTP_REQUIRED = `${PROJECT_NAME}/${CONTAINER_NAME}/OTP_REQUIRED`;
export const CANCEL_OTP = `${PROJECT_NAME}/${CONTAINER_NAME}/CANCEL_OTP`;
export const SWITCH_TENANT = `${PROJECT_NAME}/${CONTAINER_NAME}/SWITCH_TENANT`;
export const SWITCH_TENANT_COMPLETE = `${PROJECT_NAME}/${CONTAINER_NAME}/SWITCH_TENANT_COMPLETE`;

export const CODE_SIGNIN = `${PROJECT_NAME}/${CONTAINER_NAME}/CODE_SIGNIN`;

export const USER_EXTEND_SESSION = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_EXTEND_SESSION`;
export const USER_EXTEND_SESSION_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_EXTEND_SESSION_SUCCESS`;
export const USER_EXTEND_SESSION_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_EXTEND_SESSION_FAIL`;

export const SIGNOUT_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNOUT_USER`;
export const SIGNOUT_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNOUT_USER_SUCCESS`;
export const SIGNOUT_USER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SIGNOUT_USER_FAIL`;

export const FORGET_PASSWORD = `${PROJECT_NAME}/${CONTAINER_NAME}/FORGET_PASSWORD`;
export const FORGET_PASSWORD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/FORGET_PASSWORD_SUCCESS`;
export const FORGET_PASSWORD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/FORGET_PASSWORD_FAIL`;

export const RESET_PASSWORD = `${PROJECT_NAME}/${CONTAINER_NAME}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/RESET_PASSWORD_FAIL`;

export const VALIDATE_RESET_PASSWORD = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_RESET_PASSWORD`;
export const VALIDATE_RESET_PASSWORD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_RESET_PASSWORD_SUCCESS`;
export const VALIDATE_RESET_PASSWORD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_RESET_PASSWORD_FAIL`;

export const VALIDATE_INIT_CHANGE_PASSWORD = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_INIT_CHANGE_PASSWORD`;
export const VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS`;
export const VALIDATE_INIT_CHANGE_PASSWORD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/VALIDATE_INIT_CHANGE_PASSWORD_FAIL`;

export const INIT_CHANGE_PASSWORD = `${PROJECT_NAME}/${CONTAINER_NAME}/INIT_CHANGE_PASSWORD`;
export const INIT_CHANGE_PASSWORD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/INIT_CHANGE_PASSWORD_SUCCESS`;
export const INIT_CHANGE_PASSWORD_PASSWORD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/INIT_CHANGE_PASSWORD_PASSWORD_FAIL`;

export const GET_NECESSITIES = 'GET_NECESSITIES';
export const GET_NECESSITIES_SUCCESS = 'GET_NECESSITIES_SUCCESS';
export const GET_NECESSITIES_FAIL = 'GET_NECESSITIES_FAIL';

export const CHANGE_APP_LOCALE = 'CHANGE_APP_LOCALE';

export const GET_CUSTOMER_PENDING_REQUEST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_PENDING_REQUEST`;
export const GET_CUSTOMER_PENDING_REQUEST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_PENDING_REQUEST_SUCCESS`;
export const GET_CUSTOMER_PENDING_REQUEST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_PENDING_REQUEST_FAIL`;

export const GET_CAMPAIGN_PENDING_REQUEST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGN_PENDING_REQUEST`;
export const GET_CAMPAIGN_PENDING_REQUEST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGN_PENDING_REQUEST_SUCCESS`;
export const GET_CAMPAIGN_PENDING_REQUEST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGN_PENDING_REQUEST_FAIL`;

export const GET_REWARD_PENDING_REQUEST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD_PENDING_REQUEST`;
export const GET_REWARD_PENDING_REQUEST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD_PENDING_REQUEST_SUCCESS`;
export const GET_REWARD_PENDING_REQUEST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD_PENDING_REQUEST_FAIL`;

export const GET_RULE_PENDING_REQUEST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_RULE_PENDING_REQUEST`;
export const GET_RULE_PENDING_REQUEST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_RULE_PENDING_REQUEST_SUCCESS`;
export const GET_RULE_PENDING_REQUEST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_RULE_PENDING_REQUEST_FAIL`;
