import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BellOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Popover, Empty, message } from 'antd';
import _startCase from 'lodash/startCase';
import styled from 'styled-components';
import { TableSecondaryText } from 'styles';

import { useSWRUtils } from 'api/utils';
import { FEATURES, IS_PF, TENANT_DISPLAY_NAME } from 'appConstants';
import { SearchBar } from 'components';
import { GridContainer } from 'components/Layout/common';
import { getNecessities, userSignOut } from 'containers/App/actions';
import { SWITCH_TENANT } from 'containers/App/constants';
import { Profile, Circle, StyledGridContainer } from 'containers/App/style';
import { useAuthUser, useFeatureFlags, useRouter, useTenant, useUserTenants } from 'hooks';
import API from 'utils/request';

const UserMenu = () => {
  const { history } = useRouter();
  const { clearCacheData } = useSWRUtils();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const { name: tenantName, displayName: tenantDisplayName } = useTenant();
  const featureFlags = useFeatureFlags();

  const { data: tenants } = useUserTenants();

  const onSelectTenant = async tenant => {
    const payload = { application_id: tenant?.oauth_application_id };

    try {
      await API.put('/v4/dash/access_tokens/change_application', payload);

      clearCacheData();

      dispatch(getNecessities(null));
      dispatch({ type: SWITCH_TENANT });
      message.success('Account switched');
    } catch (error) {
      message.error(error?.message ?? 'There was an error switching accounts');
    }
  };

  const onLogOutClick = () => {
    dispatch(userSignOut(history));
  };

  const PopoverTitle = () => {
    return (
      <div
        style={{
          padding: '0 16px 12px'
        }}
      >
        <GridContainer alignItems="center" gap="0 12px" columns="32px auto" onClick={() => {}}>
          {IS_PF === '1' ? (
            <>
              <Circle>
                {!!authUser?.email
                  ? authUser.email[0]?.toUpperCase()
                  : localStorage.getItem('email')
                  ? localStorage.getItem('email')?.[0]?.toUpperCase()
                  : 'P'}
              </Circle>
              <div>{authUser?.email || localStorage.getItem('email')}</div>
            </>
          ) : (
            <>
              <Circle>{!!authUser?.email ? authUser.email[0]?.toUpperCase() : 'P'}</Circle>
              <div>{authUser?.email}</div>
            </>
          )}
          <div />
          <TableSecondaryText style={{ lineHeight: '1' }}>
            {TENANT_DISPLAY_NAME[tenantName] || 'Active Tenant'}
          </TableSecondaryText>
        </GridContainer>
      </div>
    );
  };

  const PopoverBody = () => {
    const [tenantList, setTenantList] = useState(tenants);

    const searchTenants = val => {
      if (!val) {
        setTenantList(tenants);
        return;
      }
      const filteredTenants = tenants.filter(t => {
        const displayName = t.oauth_application_name.toLowerCase();
        const searchTerm = val.toLowerCase();

        return displayName.includes(searchTerm);
      });
      setTenantList(filteredTenants);
    };

    return (
      <div
        style={{
          borderBottom: '1px solid #E4E4E4'
        }}
      >
        {tenants.length > 11 && (
          <div style={{ padding: '0.875rem' }}>
            {/*@ts-ignore*/}
            <SearchBar onSearch={searchTenants} />
          </div>
        )}
        <TenantListContainer data-testid={'TenantListContainer'}>
          {tenantList.length > 0 ? (
            tenantList.map((tenant, index) => (
              <StyledGridContainer
                isGrayLink
                key={index}
                alignItems="center"
                gap="0 12px"
                columns="32px auto"
                margin={'0.5rem 0'}
                onClick={() => {
                  onSelectTenant(tenant);
                }}
              >
                <Circle isGrayLite>
                  {tenant?.oauth_application_schema_name[0]?.toUpperCase()}
                </Circle>
                <div>
                  {_startCase(tenant.oauth_application_name)}
                  <TableSecondaryText style={{ lineHeight: '1' }}>
                    ID: {tenant?.oauth_application_id}
                  </TableSecondaryText>
                </div>
              </StyledGridContainer>
            ))
          ) : (
            <Empty
              description={'There are no results matching your search'}
              style={{ margin: '0.875rem 0' }}
            />
          )}
        </TenantListContainer>
      </div>
    );
  };

  const PopoverFooter = () => {
    return (
      <>
        {featureFlags[FEATURES.NOTIFICATION_PREFERENCES.key] && (
          <div style={{ padding: '12px 16px 0' }}>
            <StyledGridContainer
              isBlackLink
              alignItems="center"
              gap="0 12px"
              columns="32px auto"
              padding="5px 0"
              onClick={() => history.push('/p/user-profile/user-notifications')}
            >
              <BellOutlined style={{ fontSize: '18px' }} />
              <div>Manage notifications</div>
            </StyledGridContainer>
          </div>
        )}
        {IS_PF === '1' ? null : (
          <>
            <div style={{ padding: '12px 16px 0' }} data-testid={'UserMenuLogout'}>
              <StyledGridContainer
                isBlackLink
                alignItems="center"
                gap="0 12px"
                columns="32px auto"
                padding="5px 0"
                onClick={onLogOutClick}
              >
                <LogoutOutlined style={{ fontSize: '18px' }} />
                <div>Log out from all accounts</div>
              </StyledGridContainer>
            </div>
          </>
        )}
      </>
    );
  };

  const PopoverContent = () => (
    <div style={{ margin: '0 -16px' }} data-testid={'UserMenuContent'}>
      <PopoverTitle />
      {tenants?.length > 1 && <PopoverBody />}
      <PopoverFooter />
    </div>
  );

  return (
    <Profile className={'tenant-display'}>
      <Popover content={<PopoverContent />} placement="rightTop" trigger="click">
        <Circle className="avatar" data-testid="UserMenu__avatar" style={{ cursor: 'pointer' }}>
          <UserOutlined className="anticon" />
        </Circle>
      </Popover>
      <TenantName className={'name-display'}>
        {TENANT_DISPLAY_NAME[tenantName] || tenantDisplayName}
      </TenantName>
    </Profile>
  );
};

UserMenu.propTypes = {};

export default UserMenu;

const TenantListContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 0.875rem;
  border-bottom: 1px solid #e4e4e4;
`;

const TenantName = styled.div`
  margin-top: 0.5rem;
  color: #989ea9;
  text-align: center;
  font-size: 0.75rem;
`;
