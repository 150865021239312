import useSWR from 'swr';

import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export interface ICustomApiList {
  id: number;
  body_params_keys: any[];
  state: string;
  module: {
    auth_type: string;
    endpoint: string;
    verb: string;
    content_type: string;
  };
  module_type: string;
  name: string;
  query_params_keys: any[];
  token: {
    auth_type: string;
    endpoint: string;
    verb: string;
    content_type: string;
    username: string;
    password: string;
    grant_type: string;
    token_key_in_response: string;
  };
}

export const useCustomApi = (id: number) => {
  const { data, error, isLoading } = useSWR<IDetailResponse<ICustomApiList>>(
    id ? `/v4/dash/external_apis/${id}` : null,
    fetcher
  );

  return { data: data?.data, error, isLoading };
};

export const useCustomApiList = (params: object) => {
  const { data, error, isLoading } = useSWR<IPaginatedResponse<ICustomApiList>>(
    `/v4/dash/external_apis?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, error, isLoading };
};
