import React, { useCallback } from 'react';
import { Menu, message } from 'antd';
import qs from 'qs';

import { METABASE_API_ROOT } from 'appConstants';
import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAnalyticsContext } from 'containers/Analytics/hooks';
import { useAuthUser } from 'hooks';

interface GraphCardExtraProps {
  token?: string;
  dataParams?: object;
  helpText?: string;
  questionId?: number;
  dateRange?: any;
}

const GraphCardExtra = (props: GraphCardExtraProps) => {
  const { dateRange, isDemoMode } = useAnalyticsContext();
  const { token, dataParams, helpText, questionId } = props;

  const { email = '' } = useAuthUser();

  const onDownload = useCallback(
    (token, dateRange, dataParams) => () => {
      message.loading('The download should begin shortly …');
      const linkNode = document.createElement('a');
      linkNode.href = `${METABASE_API_ROOT}/api/embed/card/${token}/query/csv?${qs.stringify({
        start_date: dateRange[0].format('YYYY-MM-DD'),
        end_date: dateRange[1].format('YYYY-MM-Dd'),
        ...(dataParams ? dataParams : {})
      })}`;
      linkNode.download = '';
      linkNode.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
      );
    },
    []
  );

  return (
    <ActionsMenu
      menu={
        <Menu>
          {/*{!isDemoMode && (*/}
          {/*  <Menu.Item key="download-report">*/}
          {/*    <a onClick={onDownload(token, dateRange, dataParams)}>Download Report</a>*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}
          {email.endsWith('@perxtech.com') && (
            <Menu.Item key="debug">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                Debug Metabase
              </a>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default GraphCardExtra;
