import React, { useState, useMemo, useCallback, memo, useEffect } from 'react';
import { Card, Empty, Spin } from 'antd';
import { Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  Tooltip,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Legend
} from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import DatePartSelect, { DatePart } from 'containers/Analytics/components/DatePartSelect';
import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import {
  abbreviateNumber,
  getDatePart,
  colorGenerator,
  showCard
} from 'containers/Analytics/utils';

import Error from '../../components/Error';
import LoyaltyTooltip from './LoyaltyTooltip';
import { getLineChartMembershipOverTimeData } from './dummyData';

const IDENTIFIER = 'loyalty_flat_breakdown_by_date_membership';

interface IProps {
  filterParams: {
    loyalty_program_id: Number;
  };
}

const LoyaltiesOverviewGraph: React.FC<IProps> = ({ filterParams }) => {
  const [activeLegend, setActiveLegend] = useState(null);
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const color = colorGenerator();
  const { loyalty_program_id } = filterParams;

  const dataParams = useMemo(
    () => ({ datepart, loyalty_program_id }),
    [datepart, loyalty_program_id]
  );

  const { card_id: questionId } = useCard(IDENTIFIER);
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();
  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );
  const hasError = tokenError || dataError;

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  let d = data?.data?.rows.map((row: Array<[number, string, string]>) => ({
    user_count: row[0],
    loyalty_tier: row[1],
    time: row[2]
  }));

  if (isDemoMode) {
    d = getLineChartMembershipOverTimeData(dateRange[0], dateRange[1], 1, datepart);
  }

  const tiers = useMemo(() => _.chain(d).groupBy('loyalty_tier').keys().sort().value(), [d]);

  const groupedLoyaltyTiers = useMemo(
    () =>
      _.chain(d)
        .groupBy('time')
        .map(x =>
          _.reduce(x, (acc, val) => ({ ...acc, [val.loyalty_tier]: val.user_count }), {
            time: x[0].time // time is the same since it is the groupby key
          })
        )
        .value(),
    [d]
  );

  const onMouseEnter = useCallback(legend => {
    const { dataKey } = legend;
    setActiveLegend(dataKey);
  }, []);

  const onMouseLeave = useCallback(_ => {
    setActiveLegend(null);
  }, []);

  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');

  const onDatepartChange = useCallback((val: DatePart) => setDatepart(val), []);

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
  }, [diff]);

  const renderLegend = (value: null | string) => {
    return <span style={{ fontSize: 12, color: '#8c8c8c', cursor: 'pointer' }}>{value}</span>;
  };

  const renderGraph = () => {
    let graph;
    if (!_.isEmpty(groupedLoyaltyTiers)) {
      graph = (
        <LineChart data={groupedLoyaltyTiers}>
          <CartesianGrid
            vertical={false}
            strokeDasharray="0 0"
            fillOpacity="0.5"
            stroke="#f5f5f5"
          />
          <XAxis
            dataKey="time"
            stroke="#9b9b9b"
            padding={{ left: 16, right: 16 }}
            tick={{ strokeWidth: 1, fontSize: 11, fill: '#9b9b9b', dy: 16 }}
            tickSize={3}
            tickFormatter={(tick: string) => moment(tick).format('MMM D')}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#d9d9d9', fontSize: 12 }}
            tickFormatter={(tick: number) => abbreviateNumber(tick)}
            label={{
              value: 'Number of members',
              angle: -90,
              fill: '#595959',
              fontSize: 11,
              fillOpacity: 0.5,
              dx: -24
            }}
          />
          <Tooltip
            animationEasing="ease-in-out"
            wrapperStyle={{
              boxShadow: '0px 0px 12px rgba(0,0,0,.08)',
              border: 'none',
              background: '#ffffff'
            }}
            content={<LoyaltyTooltip title="Members" showTotal={true} />}
            formatter={value => value.toLocaleString()}
            cursor={{ stroke: '#8c8c8c', fillOpacity: 0.5, strokeWidth: 1 }}
            itemStyle={{ fontSize: 12 }}
            labelStyle={{ display: 'none', visibility: 'hidden', pointerEvents: 'none' }}
          />
          <Legend
            iconType="line"
            iconSize={12}
            wrapperStyle={{
              bottom: -16
            }}
            formatter={renderLegend}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          {tiers.map((tier: string, index: number) => (
            <Line
              type="monotone"
              key={tier}
              dataKey={tier}
              stroke={color.next().value}
              strokeOpacity={!activeLegend ? 1 : activeLegend === tier ? 1 : 0.1}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      );
    } else {
      graph = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
      <ResponsiveContainer debounce={500} height={232}>
        {graph}
      </ResponsiveContainer>
    );
  };

  if (!showCard(cards, IDENTIFIER) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card title="Membership Over Time" style={{ height: 424 }} bodyStyle={{ height: '100%' }}>
        <Row justify="end" align="middle" style={{ marginBottom: 16 }}>
          <DatePartSelect value={datepart} onChange={onDatepartChange} />
        </Row>

        {hasError && <Error retry={onRetry} />}
        {!d && !hasError && (
          <Row justify="center" align="middle" style={{ height: '250px' }}>
            <Spin size="large" />
          </Row>
        )}
        {d && renderGraph()}
      </Card>
    </GridItem>
  );
};

export default memo(LoyaltiesOverviewGraph);
