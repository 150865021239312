import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { useField, useFormikContext } from 'formik';

import FItem from './FItem';

type FItemInputNumberProps = InputNumberProps & {
  name: string;
  label?: string;
  formItemProps?: FormItemProps;
};

const FItemInputNumber = ({
  name,
  label,
  formItemProps,

  ...props
}: FItemInputNumberProps) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <FItem meta={meta} label={label} htmlFor={name} {...formItemProps}>
      <InputNumber
        id={name}
        {...field}
        onChange={val => formik.setFieldValue(name, val)}
        onBlur={() => formik.setFieldTouched(name)}
        {...props}
      />
    </FItem>
  );
};

export default FItemInputNumber;
