import React, { memo, useState } from 'react';
import { Card } from 'antd';
import { Col, Row } from 'antd';
import { TableProps } from 'antd/lib/table';
import { TableMainText, TableSecondaryText } from 'styles';

import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import CampaignFilter from 'containers/Analytics/components/CampaignFilter';
import { HeatMapGraph } from 'containers/Analytics/pages/graphs';
import FunnelGraphWrapper from 'containers/Analytics/pages/graphs/FunnelGraph';
import { useTenant } from 'hooks';

import MetricsCard from '../../components/MetricsCard';
import { CardsRow } from '../../styles';
import {
  CampaignTableGraph,
  CampaignsEngagementGraph,
  CampaignVSGraph,
  CampaignStackedHistogram
} from '../graphs';
import * as S from './styles';

const Games = () => {
  const { name: tenantName } = useTenant();
  const [filterParams, setFilterParams] = useState({});

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CampaignFilter onChange={setFilterParams} campaignType={'game'} />
        </Col>
        <Col xs={24}>
          <CampaignsEngagementGraph
            yAxisLabel={'Game Tries'}
            identifier={[
              'campaign_flat_game_breakdown_by_date_engagement',
              'campaign_flat_game_breakdown_by_date_view',
              'campaign_flat_game_breakdown_by_date_completion'
            ]}
            filterParams={filterParams}
          />
        </Col>
      </Row>

      <S.CardHeader style={{ marginLeft: '26px', marginBottom: '-64px', marginTop: '40px' }}>
        Summary
      </S.CardHeader>
      <CardsRow gutter={[0, 16]} style={{ paddingTop: '70px' }}>
        <MetricsCard
          identifier="campaign_flat_game_total_active_campaign"
          description="TOTAL ACTIVE CAMPAIGNS"
          helpText="Total number of current active campaigns"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_game_total_unique_view"
          description="TOTAL UNIQUE VIEWS"
          helpText="Number of unique users that viewed your campaign"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_game_engagement_rate"
          description="ENGAGEMENT RATE"
          helpText="Percentage of audience reached who completed the campaign"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_game_active_user"
          description="USERS WHO REDEEMED"
          helpText="Campaign - Game Active User"
          filterParams={filterParams}
        />
      </CardsRow>

      <GridRenderer perRow={1} gutter={16}>
        <S.Cards md={24}>
          <GamesTopByEngagementRateBI filterParams={filterParams} />
        </S.Cards>
      </GridRenderer>
      <S.SectionHeader>Customer Insights</S.SectionHeader>
      <GridRenderer perRow={1} gutter={16}>
        <CampaignVSGraph
          identifier="campaign_flat_game_new_vs_returning_user"
          title="New vs Returning Users"
          filterParams={filterParams}
          helpText="Percentages of first time users vs returning users"
        />
      </GridRenderer>
      <GridRenderer perRow={2} gutter={16}>
        <HeatMapGraph
          identifier="campaign_flat_game_popular_redemption_day"
          title="Popular Redemption Days"
          filterParams={filterParams}
          helpText="Which specific day and timing users have been issued the most vouchers. The level of vouchers issued is indicated by the intensity of the colour spectrum."
        />

        <FunnelGraphWrapper
          identifier="campaign_flat_game_funnel"
          title="Funnel View"
          filterParams={filterParams}
          helpText="Funnel View Graph for (View, Issue, Redeem, Gift, Expire)"
        />
        {tenantName !== TENANTS.RAZER && (
          <>
            <CampaignStackedHistogram
              identifier="campaign_flat_game_age"
              title="Age"
              yAxisLabel={'Game Tries'}
              filterParams={filterParams}
              legend1={'Games Issued'}
              legend2={'Games Played'}
            />
            <CampaignStackedHistogram
              identifier="campaign_flat_game_gender"
              title="Gender"
              yAxisLabel={'Game Tries'}
              filterParams={filterParams}
              legend1={'Games Issued'}
              legend2={'Games Played'}
            />
            <GamesStateBI filterParams={filterParams} />
            <GamesClusterBI filterParams={filterParams} />
          </>
        )}
      </GridRenderer>
    </>
  );
};

const GamesStateBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    // scroll: { x: 'scroll' },
    columns: [
      {
        title: 'State Name',
        key: 'primary_cluster_state',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Game Tries Issued"
            tooltip="Difference between revenue from the Game campaign and the cost of issued rewards"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Game Tries Completed"
            tooltip="Percentage of Game campaigns profit out of cost of issued rewards"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_game_state"
        title="Location - State"
        minHeight="412px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

const GamesClusterBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    columns: [
      {
        title: 'Cluster Name',
        key: 'primary_cluster',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Engagements"
            tooltip="Difference between revenue from the Game campaign and the cost of issued rewards"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completions"
            tooltip="Percentage of Game campaigns profit out of cost of issued rewards"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_game_cluster"
        title="Location - Cluster"
        minHeight="412px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

const GamesTopByEngagementRateBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    scroll: { x: 1500 },
    columns: [
      {
        title: 'Campaign Name',
        key: 'campaign_name',
        dataIndex: '[2]',
        render: (text, record) => {
          return (
            <>
              <TableMainText>{record[2]}</TableMainText>
              <TableSecondaryText>ID: {record[1]}</TableSecondaryText>
            </>
          );
        }
      },
      {
        title: (
          <TableHeader
            title="Total API Calls"
            tooltip="Total number of times campaign/id was called for campaign "
          />
        ),
        key: 'total_views',
        dataIndex: '[3]',
        render: (text, record) => <span>{record[3]?.toLocaleString()}</span>
      },
      {
        title: <TableHeader title="Total unique views" tooltip="Total number of unique views" />,
        key: 'total_unique_views',
        dataIndex: '[4]',
        render: (text, record) => <span>{record[4]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Unique users who got game tries"
            tooltip="Number of users who have been issued game tries"
          />
        ),
        key: 'user_enrolled',
        dataIndex: '[5]',
        render: (text, record) => {
          return <span>{record[5]?.toLocaleString()}</span>;
        }
      },
      {
        title: (
          <TableHeader
            title="Total Game Tries Issued"
            tooltip="Number of game tries that have been issued to users"
          />
        ),
        key: 'unique_view',
        dataIndex: '[6]',
        render: (text, record) => <span>{record[6]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Total Game Tries Redeemed"
            tooltip="Total times games have been played"
          />
        ),
        key: 'games_issued',
        dataIndex: '[7]',
        render: (text, record) => <span>{record[7]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Unique users who redeemed game tries"
            tooltip="Number of unique users who have played games"
          />
        ),
        key: 'games_completed',
        dataIndex: '[8]',
        render: (text, record) => <span>{record[8]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completion rate"
            tooltip="Total number of game tries redeemed/ total number of game tries issued "
          />
        ),
        key: 'completion_rate',
        dataIndex: '[9]',
        render: (text, record) => <span>{record[9]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_game_top_campaign_by_performance"
        title="Performance"
        tableProps={tableProps}
        pagination={true}
        scrollX={true}
        filterParams={filterParams}
      />
    </>
  );
};

interface TableHeaderProps {
  title: string;
  tooltip?: string;
}

const TableHeader = ({ title, tooltip }: TableHeaderProps) => {
  return <>{title}</>;
};

export default memo(Games);
