import { message } from 'antd';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';
import { EventEmitter, EVENTS } from 'utils/events';
import { checkPayloadForFile } from 'utils/prepareFormData';
import API from 'utils/request';

import {
  searchCustomerFail,
  getCustomerVouchersSuccess,
  getCustomerVouchersFail,
  getCustomersSuccess,
  getCustomersFail,
  searchCustomerSuccess,
  issueVoucherSuccess,
  getCustomerVouchersPendingRequestSuccess,
  getCustomerVouchersPendingRequestFail
} from './actions';
import {
  GET_CUSTOMER,
  GET_CUSTOMER_VOUCHERS,
  SEARCH_CUSTOMER,
  UPDATE_CUSTOMER,
  ACTIVATE_CUSTOMER,
  DEACTIVATE_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  GET_CUSTOMER_VOUCHERS_PENDING_REQUEST,
  GET_CUSTOMER_GAME_TRANSACTIONS,
  GET_CUSTOMER_GAME_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_GAME_TRANSACTIONS_FAIL,
  GET_CUSTOMER_PENDING_GAME_TRANSACTIONS
} from './constants';

const searchCustomer = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getCustomers = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getCustomerVouchers = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getCustomerGameTransactions = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getUserEmail = (url, headers) => {
  return api({
    url: url,
    headers: headers
  });
};
const updateCustomer = (url, body, headers) => {
  return api({
    url: url,
    headers: headers,
    body: body,
    method: 'put'
  });
};

const postAPICall = (url, body, headers) => {
  body = checkPayloadForFile(body, headers);
  return api({
    url: url,
    headers: headers,
    body: body,
    method: 'post'
  });
};

function* customersFind(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchCustomer, `${API_ROOT}/v4/dash/customers`, params, headers);
    yield put(searchCustomerSuccess(response.data));
  } catch (e) {
    yield put(searchCustomerFail(e.data));
  }
}

function* customerVouchersGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      getCustomerVouchers,
      params.state === 'pending'
        ? `${API_ROOT}/v4/dash/customers/${params.customer_id}/voucher_requests`
        : `${API_ROOT}/v4/dash/customers/${params.customer_id}/vouchers`,
      params,
      headers
    );
    params.state === 'pending'
      ? yield put(getCustomerVouchersPendingRequestSuccess(response.data))
      : yield put(getCustomerVouchersSuccess(response.data));
  } catch (e) {
    params.state === 'pending'
      ? yield put(getCustomerVouchersPendingRequestFail(e.data))
      : yield put(getCustomerVouchersFail(e.data));
  }
}

function* customersGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCustomers, `${API_ROOT}/v4/dash/customers`, params, headers);
    yield put(getCustomersSuccess(response.data));
  } catch (e) {
    yield put(getCustomersFail(e.data));
  }
}

function* issueRewards(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };
  try {
    const response = yield call(
      postAPICall,
      `${API_ROOT}/v4/dash/customers/${params.customer_id}/vouchers/issue_vouchers`,
      { rewards: params.rewards },
      headers
    );
    const data = response.data;
    if (Array.isArray(data)) {
      const errors = data
        .filter(item => item?.error_message)
        .map(item => `${item?.reward_campaign_id}: ${item?.error_message}`);
      if (errors.length > 0) {
        let error = { message: errors[0], description: null };
        if (errors.length > 1) {
          error = {
            message: `Errors occurred while issuing rewards`,
            description: errors
          };
        }
        EventEmitter.dispatch(EVENTS.REWARD_ISSUE_ERROR, error);
        yield put(getCustomersFail(error));
      } else {
        yield put(issueVoucherSuccess(response.data));
        EventEmitter.dispatch(EVENTS.REWARD_ISSUED, response.data);
        message.success('Reward issued successfully');
      }
    } else if (data && data?.customer_request_ids) {
      yield put(issueVoucherSuccess(response.data));
      EventEmitter.dispatch(EVENTS.REWARD_ISSUED, response.data);
      message.success('Reward issue request submitted for approval');
    } else {
      const errorMessage = 'Unexpected response format';
      EventEmitter.dispatch(EVENTS.REWARD_ISSUE_ERROR, { message: errorMessage });
      yield put(getCustomersFail({ message: errorMessage }));
    }
  } catch (e) {
    const errorMessage =
      e?.response?.data?.message || e?.message || e || 'Error occurred while issuing rewards';
    EventEmitter.dispatch(EVENTS.REWARD_ISSUE_ERROR, errorMessage);
    yield put(getCustomersFail({ message: errorMessage }));
  }
}

function* customerGameTransactionsGet(action) {
  const { gameId, params } = action.payload;
  const authUser = yield select(makeSelectAuthUser());
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };
  try {
    const response = yield call(
      getCustomerGameTransactions,
      `${API_ROOT}/v4/dash/campaigns/${gameId}/game_transactions`,
      params,
      headers
    );
    let responseData = response?.data?.data || [];
    const meta = response?.data?.meta || {};
    responseData = yield all(
      responseData.map(record =>
        call(function* () {
          if (record.created_by_id) {
            try {
              const user = yield call(
                getUserEmail,
                `${API_ROOT}/v4/dash/users/${record.created_by_id}`,
                headers
              );
              const email = user?.data?.data?.email || '';
              return { ...record, email };
            } catch (error) {
              console.error('Failed to fetch user email', error);
              return { ...record, user_email: null };
            }
          }
          return record;
        })
      )
    );
    // todo after resolve responseData then put GET_CUSTOMER_GAME_TRANSACTIONS_SUCCESS
    yield put({
      type: GET_CUSTOMER_GAME_TRANSACTIONS_SUCCESS,
      payload: { data: responseData, meta }
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || 'Failed to fetch game transactions';
    yield put({ type: GET_CUSTOMER_GAME_TRANSACTIONS_FAIL, payload: { error: errorMessage } });
  }
}

function* customerSaga() {
  yield takeLatest(GET_CUSTOMER, commonSaga);
  yield takeLatest(GET_CUSTOMER_VOUCHERS, customerVouchersGet);
  yield takeLatest(GET_CUSTOMER_VOUCHERS_PENDING_REQUEST, customerVouchersGet);
  yield takeLatest(SEARCH_CUSTOMER, customersFind);
  //@ts-ignore
  yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(ACTIVATE_CUSTOMER, commonSaga);
  yield takeLatest(DEACTIVATE_CUSTOMER, commonSaga);
  yield takeLatest(GET_CUSTOMERS, customersGet);
  yield takeLatest(DELETE_CUSTOMER, commonSaga);
  yield takeLatest('ISSUE_REWARDS', issueRewards);
  yield takeLatest(GET_CUSTOMER_GAME_TRANSACTIONS, customerGameTransactionsGet);
  yield takeLatest(GET_CUSTOMER_PENDING_GAME_TRANSACTIONS, commonSaga);
}

export default customerSaga;
