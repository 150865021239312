import React, { useEffect } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { TENANTS, THEMED_TENANTS } from 'appConstants';
import { SWITCH_TENANT_COMPLETE, TOKEN_SIGNIN } from 'containers/App/constants';
import IndexPage from 'containers/App/pages';
import PublicCustomer from 'containers/Customer/pages';
import { useTenant, useTypedSelector } from 'hooks';
import ForgetPassword from 'pages/public/ForgetPassword';
import InviteTenant from 'pages/public/InviteTenant';
import { DashboardLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';
import Registration from 'pages/public/Registration';
import ResetPassword from 'pages/public/ResetPassword';
import SignInPage from 'pages/public/SignInPage';

import RestrictedRoute from './RestrictedRoute';
import { getFilteredMenu } from './getMenu';
import ScrollToTop from './scrollToTop';
import { PerxState } from 'MyTypes';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [token] = useQueryParam('token');
  const { status, switcher, currentTheme, themes } = useThemeSwitcher();
  const permissions = useSelector(
    (state: PerxState) => state.permissions.permissions,
    shallowEqual
  );
  const switchingTenant = useTypedSelector(state => state?.global?.switchingTenant);
  const { name: tenantName } = useTenant();
  const menu = getFilteredMenu(permissions);

  const [code] = useQueryParam('code');
  const [codeTenant] = useQueryParam('tenant');
  const isCodeSignin = !!code && !!codeTenant;

  useEffect(() => {
    // initiate token login process if token query param detected
    // but if the path contains "customers", that means the user is trying
    // to access the Public Customer page, which doesn't need to perform token signin.
    const isPublicPage = location.pathname.startsWith('/customers');
    const isTokenSignin = !!token && !isPublicPage && !isCodeSignin;

    if (isTokenSignin) {
      const payload = {
        bearer_token: token,
        token: token,
        expires_at: null,
        password_expires_at: null,
        email: null
      };

      dispatch({ type: TOKEN_SIGNIN, payload: payload, location, history });
    }
  }, [token, isCodeSignin]);

  useEffect(() => {
    if (switchingTenant) {
      history.push(menu.length > 0 ? menu[0].url : '/signin');
      dispatch({ type: SWITCH_TENANT_COMPLETE });
    }
  }, [permissions]);

  // Set theme to Zeal if it is not set
  useEffect(() => {
    if (tenantName === TENANTS?.ZEAL && currentTheme !== themes.zeal) {
      switcher({ theme: themes.zeal });
    } else if (
      (tenantName === TENANTS?.RUSH || tenantName === TENANTS?.CWC) &&
      currentTheme !== themes.rush
    ) {
      switcher({ theme: themes.rush });
    } else if (tenantName === TENANTS?.AFFIRM && currentTheme !== themes.affirm) {
      switcher({ theme: themes.affirm });
    } else if (!THEMED_TENANTS.includes(tenantName) && currentTheme !== themes.light) {
      switcher({ theme: themes.light });
    }
  }, [tenantName, currentTheme, themes, switcher]);

  if (status === 'loading') {
    return <DashboardLoading />;
  }

  return (
    <ScrollToTop>
      <Switch>
        <Redirect
          exact
          from="/"
          to={isCodeSignin ? `/signin?tenant=${codeTenant}&code=${code}` : '/p'}
        />
        <RestrictedRoute path="/p" component={IndexPage} />
        {/* for StarHub */}
        <Route exact path="/customers/" component={PublicCustomer} />
        <Redirect from="/login" to="/signin" />
        <Route exact path="/signin" component={SignInPage} />
        <Route exact path="/reset_password" component={ResetPassword} />
        <Route exact path="/invitation/accept" component={ResetPassword} />
        <Route exact path="/forgot-password" component={ForgetPassword} />
        <Route exact path="/set-password" component={ForgetPassword} />
        <Route exact path="/invite_tenant" component={InviteTenant} />
        <Route exact path="/registration" component={Registration} />
        <Route component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  );
};

export default App;
