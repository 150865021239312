import { useAxios } from 'hooks';
import { IPaginatedResponse } from 'utils/types';

export interface IRole {
  id: number;
  name: string;
  state: string;
  permissions: {}[];
}
interface IAclAttributes {
  labels?: number[];
  categories?: number[];
  tags?: number[];
  merchant_accounts?: number[];
}

export interface IAccessGroup {
  id: number;
  acl_attributes: IAclAttributes;
  full_record_access: boolean;
  name: string;
  number_of_users: number;
  state: string;
}
export interface IUser {
  id: number;
  access_groups: IAccessGroup[];
  email: string;
  is_locked: boolean;
  name: string;
  roles: Pick<IRole, 'id' | 'name'>[];
  state: string;
}
export interface IRolesResponse extends IPaginatedResponse<IRole> {}

export const useRoles = () =>
  useAxios<IRolesResponse>('/v4/dash/roles', { params: { size: 1000 } });
