import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Radio, Spin } from 'antd';
import { Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { DB_RECORD_DATETIME_FORMAT } from 'appConstants';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { getOverviewBreakdownData } from 'containers/Analytics/pages/graphs/dummyData';
import { getDatePart } from 'containers/Analytics/utils';
import { useLocalization } from 'hooks';

import DatePartSelect, { DatePart } from '../../components/DatePartSelect';
import Error from '../../components/Error/Error';
import { TickProps, ToolTipProps } from './HistogramGraph';

const QUESTIONS_IDENTIFIERS = {
  views: 'reward_flat_reward_breakdown_by_date_view',
  issues: 'reward_flat_reward_breakdown_by_date_issued',
  redeemed: 'reward_flat_reward_breakdown_by_date_redeemed'
};

const RewardsOverviewGraph = ({ filterParams }) => {
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const [tab, setTab] = useState('issues');
  const [tooltipMessage, setTooltipMessage] = useState(
    'Discover the total number of reward vouchers issued.'
  );

  const dataParams = useMemo(() => ({ datepart, ...filterParams }), [datepart, filterParams]);
  const { card_id: questionId } = useCard(QUESTIONS_IDENTIFIERS[tab]);
  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate, isDataValidating } =
    useMetabase(questionId, dataParams);

  const { dateRange } = useAnalyticsContext();
  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');

  const onTabChange = evt => {
    setTab(evt.target.value);
    changeTooltipMessage(evt.target.value);
  };
  const onDatepartChange = useCallback(val => setDatepart(val), []);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;

    return <Graph data={data} diff={diff} datepart={datepart} tab={tab} />;
  };

  const changeTooltipMessage = targetTab => {
    switch (targetTab) {
      case 'views':
        setTooltipMessage('Discover the total number of reward views.');
        break;
      case 'issues':
        setTooltipMessage('Discover the total number of reward vouchers issued.');
        break;
      case 'redeemed':
        setTooltipMessage('Discover the total number of reward vouchers redeemed.');
        break;
      case 'revenue':
        setTooltipMessage(
          'Multiplication of selling price of the paid rewards and number of vouchers of that rewards issued.'
        );
        break;
      default:
        setTooltipMessage('Discover the total number of reward views.');
        break;
    }
  };

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
  }, [diff]);

  return (
    <div
      style={{
        width: '100%',
        padding: '2rem',
        backgroundColor: '#ffffff',
        border: '1px solid #e8e8e8'
      }}
    >
      <Row justify="space-between" style={{ marginBottom: '4rem' }}>
        <div>
          <Radio.Group name="tab" value={tab} onChange={onTabChange}>
            <Radio.Button value="issues">Issued</Radio.Button>
            <Radio.Button value="views">Views</Radio.Button>
            <Radio.Button value="redeemed">Redeemed</Radio.Button>
          </Radio.Group>
        </div>
        {datepart ? <DatePartSelect value={datepart} onChange={onDatepartChange} /> : null}
      </Row>
      <div
        style={{
          height: 300,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '0.4rem'
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

const CustomDateTick = ({ x, y, payload }: TickProps) => {
  const { timezone } = useLocalization();

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} fill="#777777" fontSize="12" textAnchor="middle">
        {moment(payload.value).tz(timezone).format(DB_RECORD_DATETIME_FORMAT)}
      </text>
    </g>
  );
};

const CustomCountTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={-20} fill="#777777" fontSize="12" textAnchor="middle">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

export const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  if (active && payload && payload.length > 0) {
    return (
      <div
        style={{ backgroundColor: '#0d517e', color: '#ffffff', padding: '0.5rem', borderRadius: 2 }}
      >
        <div style={{ color: '#ffffff' }}>{payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#eeeeee', fontSize: 11 }}>
          {moment(label).format('MMM D YYYY, h:mm a')}
        </div>
      </div>
    );
  }
  return null;
};

const getInterval = (diff, datepart) => {
  if (datepart === 'hour') {
    if (diff <= 0) return 0;
    else return 'preserveStartEnd';
  } else if (datepart === 'day') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 5;
    else if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else if (datepart === 'week') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 2;
    else if (diff <= 50) return 5;
    else return 'preserveStartEnd';
  } else if (datepart === 'month') {
    if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else {
    return 'preserveStartEnd';
  }
};

const Graph = ({ data, diff, datepart, tab }) => {
  const { dateRange, isDemoMode } = useAnalyticsContext();

  let d = (data?.data?.rows || []).map(row => ({ value: row[0], date: row[1] }));

  if (isDemoMode) {
    const modifiers = {
      issued: 1,
      views: 20,
      redeemed: 10,
      revenue: 1
    };

    d = getOverviewBreakdownData(dateRange[0], dateRange[1], modifiers[tab], datepart);
  } else {
    if (_isEmpty(data?.data?.rows)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <ResponsiveContainer>
      <LineChart data={d}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" stroke="#e4e4e4" />
        <XAxis
          dataKey="date"
          interval={getInterval(diff, datepart)}
          tick={<CustomDateTick />}
          padding={{ left: 16, right: 16 }}
          stroke="#9b9b9b"
        />
        <YAxis axisLine={false} tickLine={false} tick={<CustomCountTick />} width={50} />
        <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#0d517e' }} />
        <Line
          dataKey="value"
          stroke="#28e495"
          strokeWidth={1}
          dot={{ r: diff > 30 ? 2 : 5 }}
          activeDot={{ r: diff > 30 ? 2 : 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

RewardsOverviewGraph.propTypes = {
  filterParams: PropTypes.object
};

export default memo(RewardsOverviewGraph);
