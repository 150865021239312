import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import moment from 'moment';
import styled from 'styled-components';

import { useAnalyticsContext } from 'containers/Analytics/hooks';

interface Props extends SelectProps {
  onChange(datepart: DatePart): void;
  value: DatePart;
  minPeriod?: DatePart;
}

export enum DatePart {
  Hourly = 'hour',
  Daily = 'day',
  Weekly = 'week',
  Monthly = 'month',
  Quarterly = 'quarter'
}

const DatePartSelect = ({ onChange, value, style = {}, minPeriod = DatePart.Daily }: Props) => {
  const { dateRange } = useAnalyticsContext();

  const start = moment(dateRange[0]).startOf('day');
  const end = moment(dateRange[1]).endOf('day');

  const options = useMemo<{ value: DatePart; label: string }[]>(() => {
    const hourDiff = Math.round(end.diff(start, 'hour', true) * 10) / 10;
    const dayDiff = Math.round(end.diff(start, 'days', true) * 10) / 10;
    const weekDiff = Math.round(end.diff(start, 'weeks', true) * 10) / 10;
    const monthDiff = Math.round(end.diff(start, 'months', true) * 10) / 10;

    const value = [];
    switch (minPeriod) {
      case DatePart.Hourly:
        if (hourDiff >= 0 && hourDiff <= 60)
          value.push({ value: DatePart.Hourly, label: 'by Hour' });
      case DatePart.Daily:
        if (dayDiff >= 1 && dayDiff <= 60) value.push({ value: DatePart.Daily, label: 'by Day' });
      case DatePart.Weekly:
        if (weekDiff >= 1 && weekDiff <= 60)
          value.push({ value: DatePart.Weekly, label: 'by Week' });
      case DatePart.Monthly:
        if (monthDiff >= 1) value.push({ value: DatePart.Monthly, label: 'by Month' });
        break;
    }

    return value;
  }, [end, start]);

  useEffect(() => {
    if (options.length && !options.find(option => option.value === value)) {
      onChange(options[0].value);
    }
  }, [options, value, onChange]);

  return <CustomSelect style={style} onChange={onChange} value={value} options={options} />;
};

export default DatePartSelect;

const CustomSelect = styled(Select)`
  .ant-select-arrow {
    top: 30%;
  }
`;

export const TitleDropdown = styled(Select)`
  font-size: 16px;

  .ant-select-selection {
    border: none;
    border-bottom: 1px dashed;

    .ant-select-selection__rendered {
      margin-right: 30px;
      margin-left: 0;
    }
  }
`;
