import React from 'react';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { FieldMetaProps } from 'formik';

import { FormItem } from 'components/Layout/Form';

interface FItemProps extends FormItemProps<any> {
  meta?: FieldMetaProps<any>;
}

const FItem = ({ meta, ...props }: FItemProps) => {
  const isTouched = meta?.touched || false;
  const hasError = meta?.error !== undefined && isTouched;
  const isValid = !meta?.error && isTouched;

  return (
    <FormItem
      validateStatus={hasError ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError ? meta?.error : undefined}
      colon={false}
      {...props}
    />
  );
};

export default FItem;
