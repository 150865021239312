import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { IS_PF } from 'appConstants';
import { userExtend } from 'containers/App/actions';
import { useAuthUser } from 'hooks';

const IdleHandler = () => {
  const authUser = useAuthUser();
  const dispatch = useDispatch();

  const getTimeout = () => {
    const now = moment();
    const expiry = moment(authUser.expires_at);

    const timeout = expiry.diff(now);
    return timeout;
  };

  const extendToken = () => {
    const timeToExpiry = getTimeout();
    const buffer = 30 * 1000; // buffer 30 seconds
    const refreshTime = timeToExpiry - buffer;
    const tokenTimeout = setInterval(() => {
      //do what when token expire
      if (IS_PF === '1') {
        if (Object.keys(authUser).length) dispatch(userExtend(authUser));
        else delete axios.defaults.headers.common['Authorization'];
      } else {
        dispatch(userExtend(authUser));
      }
    }, refreshTime);

    return () => {
      clearTimeout(tokenTimeout);
    };
  };

  useEffect(() => {
    return extendToken();
  }, [authUser]);

  return <div />;
};

export default IdleHandler;
