import produce from 'immer';
import merge from 'lodash/merge';

import { removeDuplicates } from 'utils/helpers';

import {
  CREATE_REWARD,
  CREATE_REWARD_SUCCESS,
  CREATE_REWARD_FAIL,
  APPROVE_REWARD,
  APPROVE_REWARD_SUCCESS,
  APPROVE_REWARD_FAIL,
  DEACTIVATE_REWARD,
  DEACTIVATE_REWARD_SUCCESS,
  DEACTIVATE_REWARD_FAIL,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_FAIL,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_REWARD,
  GET_REWARD_SUCCESS,
  GET_REWARD_FAIL,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCES_FAIL,
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS,
  GET_MERCHANTS_FAIL,
  GET_LOYALTY_LIST,
  GET_LOYALTY_LIST_FAIL,
  GET_LOYALTY_LIST_SUCCESS,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  UPLOAD_AUDIENCE_SUCCESS,
  SELECT_SCHEDULE,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED
} from './constants';

const initialState = {
  loading: false,
  tags: [],
  merchants: [],
  rewards: {
    data: [],
    meta: {}
  },
  brands: [],
  loyalty_list: [],
  reward: {},
  audiences: [],
  isSelectedSchedule: true
};

function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REWARDS: {
      return {
        ...state,
        loading: true,
        rewards: {
          data: [],
          meta: {}
        }
      };
    }
    case GET_REWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isSelectedSchedule: true,
        rewards: {
          data: removeDuplicates(action.payload.data, 'id'),
          meta: action.payload.meta
        }
      };
    }
    case GET_REWARDS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_TAGS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tags: merge([], state.tags, action.payload.data)
      };
    }
    case GET_TAGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_AUDIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        audiences: merge([], state.audiences, action.payload.data)
      };
    }
    case GET_AUDIENCES:
    case GET_BRANDS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_BRANDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        brands: merge([], state.brands, action.payload.data)
      };
    }
    case GET_AUDIENCES_FAIL:
    case GET_BRANDS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_MERCHANTS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_MERCHANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        merchants: action.payload.data
      };
    }
    case GET_MERCHANTS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_REWARD: {
      return {
        ...state,
        loading: true,
        error: {}
      };
    }
    case GET_REWARD_SUCCESS: {
      let reward = action.payload.data;
      if (action.payload.data?.changes) {
        reward = {
          ...action.payload.data.reward,
          changedProperties: new Set(action.payload.data?.changes?.changed_attributes || [])
        };
      }

      return {
        ...state,
        loading: false,
        isSelectedSchedule: true,
        reward
      };
    }
    case GET_REWARD_FAIL: {
      return {
        ...state,
        error: action.error.data,
        loading: false
      };
    }
    case CREATE_REWARD: {
      return {
        ...state,
        loading: true,
        reward: {}
      };
    }
    case CREATE_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case CREATE_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case APPROVE_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case APPROVE_REWARD_SUCCESS: {
      return produce(state, draft => {
        draft.loading = false;
        draft.reward = action.payload.data;

        // TODO: might not need this anymore. New rewardslist table won't put all the rewards in redux anymore
        const rIndex = draft.rewards.data.findIndex(item => item.id === action.payload.data.id);
        if (rIndex > -1) {
          draft.rewards.data[rIndex] = action.payload.data;
        }
      });
    }
    case APPROVE_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case DEACTIVATE_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case DEACTIVATE_REWARD_SUCCESS: {
      const newState = Object.assign({}, state);
      const rewardIndex = newState.rewards.data.findIndex(
        item => item.id === action.payload.data.id
      );

      // TODO: might not need this anymore. New rewardslist table won't put all the rewards in redux anymore
      if (rewardIndex > -1) {
        newState.rewards.data[rewardIndex] = action.payload.data;
      }

      return {
        ...state,
        ...newState,
        loading: false,
        reward: action.payload.data
      };
    }
    case DEACTIVATE_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        reward: action.payload.data
      };
    }
    case GET_AUDIENCE_INFO_FAILED:
    case UPDATE_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_LOYALTY_LIST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_AUDIENCE_INFO: {
      return {
        ...state
        // loading: true
      };
    }
    case GET_LOYALTY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loyalty_list: action.payload.data
      };
    }
    case GET_LOYALTY_LIST_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case SEARCH_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: removeDuplicates(action.payload.data, 'id'),
          meta: action.payload.meta
        }
      };
    }
    case SEARCH_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPLOAD_AUDIENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        audiences: merge([], state.audiences, [action.payload])
      };
    }

    case SELECT_SCHEDULE: {
      const isSelectedSchedule = action.payload;
      return {
        ...state,
        isSelectedSchedule
      };
    }

    case GET_AUDIENCE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        audience: action.payload.data
      };
    }

    default:
      return state;
  }
}

export default rewardReducer;
