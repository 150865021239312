import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import _debounce from 'lodash/debounce';
import _differenceWith from 'lodash/differenceWith';
import _isNil from 'lodash/isNil';

import { ICustomApiList, useCustomApiList } from 'api/customApi';
import API from 'utils/request';

const fetchCustomApis = async (merchantIds: number[], params: {}) => {
  return await Promise.all(
    merchantIds.map(async (id: number) => {
      const res = await API.get(`/v4/dash/external_apis/${id}`, params);
      return res.data.data;
    })
  );
};

export const CustomApiSelect = ({
  mode = null,
  placeholder = 'Please select an API',
  preSelected = false,
  value,
  onOptionSelect,
  ...props
}) => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [preselectedItem, setPreselectedItem] = useState<ICustomApiList[]>([]);
  const [items, setItems] = useState<ICustomApiList[]>([]);
  const [page, setPage] = useState<number>(1);

  const { data, meta, isLoading, error } = useCustomApiList({
    search_string: searchString,
    state: 'active',
    page,
    module_type: 'reward_redemption_api'
  });

  const onSearch = useCallback(val => {
    setItems([]);
    setSearchString(val);
  }, []);
  const onSelect = useCallback(
    value => {
      setSearchString(null);
      const selectedOption = items.find(item => item.id === value);
      if (selectedOption && onOptionSelect) {
        onOptionSelect(selectedOption);
      }
    },
    [items, props]
  );

  useEffect(() => {
    if (data?.length) {
      setItems(oldItems => [...oldItems, ...data]);
    }
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();

    async function getCustomApiObjects(value) {
      let values: number[] = Array.isArray(value)
        ? value
        : _isNil(value) || value === ''
        ? []
        : [value];

      const customApis: ICustomApiList[] = [];
      const missingIds: number[] = [];
      for (const id of values) {
        let merch: ICustomApiList | undefined =
          items.find(m => m.id === id) || preselectedItem.find(m => m.id === id);
        if (merch) {
          customApis.push(merch);
        } else {
          // if we could not find it, add it to the list of customApis to be fetched
          missingIds.push(id);
        }
      }
      // fetch missing customApis
      const missingCustomApis =
        missingIds.length > 0
          ? await fetchCustomApis(missingIds, { signal: controller.signal })
          : [];

      // set preselected list
      setPreselectedItem([...customApis, ...missingCustomApis]);
    }

    getCustomApiObjects(value);

    return () => {
      setPreselectedItem([]);
      controller.abort();
    };
  }, [value, items]);

  const handleScroll = e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    const totalPage = meta?.total_pages || 0;
    if (!isLoading && scrollTop + offsetHeight >= scrollHeight - 500 && page < totalPage) {
      setPage(value => value + 1);
    }
  };

  if (error) return null;

  return (
    <Select
      showSearch
      value={value}
      data-testid="customApi-select"
      loading={isLoading}
      placeholder={isLoading ? 'Loading...' : placeholder}
      style={{ width: '100%' }}
      mode={mode}
      onSearch={_debounce(onSearch, 400)}
      onSelect={onSelect}
      onPopupScroll={handleScroll}
      {...props}
      onBlur={e => {
        setSearchString(null);
        e.preventDefault();
      }}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
    >
      {preselectedItem.map(item => (
        <Select.Option key={Math.floor(1000 + Math.random() * 9000)} value={item.id}>
          {item.name} (ID: {item.id})
        </Select.Option>
      ))}
      {_differenceWith(items, preselectedItem, (x, y) => x.id === y.id).map(option => (
        <Select.Option key={Math.floor(1000 + Math.random() * 9000)} value={option.id}>
          {option.name} (ID: {option.id})
        </Select.Option>
      ))}
    </Select>
  );
};
