import React, { memo, ReactNode } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

interface Action {
  title: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export interface ActionsMenuProps {
  actionsList?: Action[] | any;
  menu?: JSX.Element;
}

const renderMenu = (actionsList: Action[]) => {
  let menuItems = [];
  if (actionsList) {
    menuItems = actionsList.map((action: any, index: number) => {
      return (
        <Menu.Item key={index}>
          <a onClick={action.onClick}>{action.title}</a>
        </Menu.Item>
      );
    });
  }
  return <Menu items={menuItems} />;
};

const ActionsMenu = (props: ActionsMenuProps) => {
  const { actionsList, menu } = props;

  return (
    <Dropdown overlay={!menu ? renderMenu(actionsList) : menu} trigger={['click']}>
      <MoreOutlined style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default memo(ActionsMenu);
