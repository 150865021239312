import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Typography, Card, Select } from 'antd';
import styled from 'styled-components';

import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import { FlexContainer } from 'components/Layout/common';
import { PageContent } from 'components/page';
import { useTenant, useAxios } from 'hooks';

import MetricsCard from '../components/MetricsCard';
import { CardsRow } from '../styles';
import LoyaltiesMembershipDistributionGraph from './graphs/LoyaltiesMembershipDistributionGraph';
import LoyaltiesOverviewGraph from './graphs/LoyaltiesOverviewGraph';
import LoyaltiesPointsOvertimeGraph from './graphs/LoyaltiesPointsOvertimeGraph';
import StackedHistogramGraph from './graphs/StackedHistogram';
import TableGraph from './graphs/TableGraph';

// https://docs.google.com/spreadsheets/d/12TZgszi0AZFofM5K5tMPsfdj0GXAdi5VQvA3mIqfDRo/edit#gid=0

const Loyalties: React.FC<RouteComponentProps> = () => {
  const [chosenLoyaltyProgramId, setChosenLoyaltyProgramId] = useState<number | null>(null);
  const { name: tenantName } = useTenant();
  const [loading, response] = useAxios('/v4/dash/loyalty_programs');

  useEffect(() => {
    // the default loyalty option should be the recently created one.
    if (response && response.data.length > 0) {
      const maxOrdering = Math.max(...response.data.map(loyalty => loyalty.ordering));
      const defaultLoyaltyProgram = response.data.find(loyalty => loyalty.ordering === maxOrdering);
      if (defaultLoyaltyProgram) {
        setChosenLoyaltyProgramId(defaultLoyaltyProgram.id);
      }
    }
  }, [response]);

  const queryData = {
    loyalty_program_id: chosenLoyaltyProgramId
  };

  return (
    <PageContent>
      <FlexContainer>
        <TitleDropdown
          value={chosenLoyaltyProgramId}
          loading={loading}
          onSelect={setChosenLoyaltyProgramId}
          dropdownMatchSelectWidth={false}
        >
          {response &&
            response.data.length > 0 &&
            response.data.map(loyaltyProgram => (
              <Select.Option value={loyaltyProgram.id} key={loyaltyProgram.id}>
                {loyaltyProgram.name}
              </Select.Option>
            ))}
        </TitleDropdown>
        <Typography.Title style={{ marginLeft: '8px' }} level={4}>
          membership insights
        </Typography.Title>
      </FlexContainer>
      <GridRenderer perRow={1} gutter={16}>
        <LoyaltiesOverviewGraph filterParams={queryData} />
      </GridRenderer>
      <GridRenderer perRow={2} gutter={16}>
        <LoyaltiesMembershipDistributionGraph filterParams={queryData} />
        <TableGraph
          identifier="loyalty_flat_earned_burned_points_per_tier"
          title="Earned and burned points per tier"
          height="345px" // VS-3440 - override default height to match neighbouring card
          filterParams={queryData}
          tableProps={{
            rowKey: (r: any) => r[0],
            columns: [
              {
                title: <FirstCell>Tier Name</FirstCell>,
                key: 'loyalty_tier',
                dataIndex: '[1]',
                render: (x: string) => <FirstCell>{x || ''}</FirstCell>
              },
              {
                title: 'Total Users',
                key: 'total_users',
                render: (x: any) => x?.[2]?.toLocaleString() || 0
              },
              {
                title: 'Total Earned Points',
                key: 'total_earned_points',
                render: (x: any) => x?.[3]?.toLocaleString() || 0
              },
              {
                title: 'Total Burned Points',
                key: 'total_burned_points',
                render: (x: any) => x?.[4]?.toLocaleString() || 0
              }
            ]
          }}
        />
      </GridRenderer>
      <SectionHeader>Loyalty Points Insights</SectionHeader>
      <GridRenderer perRow={1} gutter={16}>
        <LoyaltiesPointsOvertimeGraph filterParams={queryData} />
      </GridRenderer>

      <CardHeader style={{ margin: '20px 0px -64px 30px' }}>General Points Overview</CardHeader>
      <CardsRow gutter={[0, 16]} style={{ paddingTop: '70px' }}>
        <MetricsCard
          identifier="loyalty_flat_engaged_user"
          description="ENGAGED USERS"
          helpText=""
        />
        <MetricsCard
          identifier="loyalty_flat_total_point_earned"
          description="POINTS EARNED"
          helpText=""
          filterParams={queryData}
        />
        <MetricsCard
          identifier="loyalty_flat_total_point_burned"
          description="POINTS BURNED"
          helpText=""
          filterParams={queryData}
        />
        <MetricsCard
          identifier="loyalty_flat_expired_point"
          description="POINTS EXPIRED"
          helpText=""
          filterParams={queryData}
        />
        <MetricsCard
          identifier="loyalty_flat_total_point_liability"
          description="POINTS LIABILITY"
          helpText=""
          filterParams={queryData}
        />
      </CardsRow>

      {tenantName !== TENANTS.RAZER && (
        <GridRenderer perRow={2} gutter={16}>
          <StackedHistogramGraph
            identifier="loyalty_flat_age"
            title="Age"
            filterParams={queryData}
          />
          <StackedHistogramGraph
            identifier="loyalty_flat_gender"
            title="Gender"
            filterParams={queryData}
          />
          <StackedHistogramGraph
            identifier="loyalty_flat_race"
            title="Ethnicity"
            filterParams={queryData}
          />
          <StackedHistogramGraph
            identifier="loyalty_flat_user_plan"
            title="User Plan"
            filterParams={queryData}
          />
        </GridRenderer>
      )}
    </PageContent>
  );
};

const CardHeader = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-left: 16px;
`;

const SectionHeader = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const FirstCell = styled.div`
  padding-left: 16px;
`;

const TitleDropdown = styled(Select)`
  .ant-select-selection {
    border: none;
    border-radius: 0px;
    background: transparent;
    font-size: 22px;
    max-width: max-content;
    min-width: 200px;

    .ant-select-selection__rendered {
      margin-right: 30px;
      margin-left: 0;
      border-bottom: 1px dashed;
    }
  }
`;

export default memo(Loyalties);
