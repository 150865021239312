import React, { useCallback } from 'react';
import { Button, Typography, Table, Select } from 'antd';
import { Field, FieldArrayRenderProps, useFormikContext } from 'formik';
import uuid from 'uuid/v4';

import { TableActions } from 'components';
import { getFileUploadSampleLink } from 'containers/BulkAction/components/BulkActionModal';
import { useTenant } from 'hooks';

const { Text } = Typography;

interface AudienceSetupProps extends FieldArrayRenderProps {}

const { Option } = Select;

const AudienceSetup = (props: AudienceSetupProps) => {
  const { push, remove } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const { name: tenantName } = useTenant();

  const handleFileUpload = useCallback((files: File[]) => {
    push({
      _id: uuid(),
      file: files[0],
      name: files[0].name,
      list_type: 'allow_list'
    });
  }, []);

  const tableColumns = [
    { title: 'Uploaded file', key: 'name', dataIndex: 'name', width: 320 },
    {
      title: 'Relation',
      key: 'relation',
      render: (_, record, index) => (
        <Field name={`user_groups.${index}.list_type`}>
          {({ field }) => (
            <Select
              value={field.value}
              onChange={value => setFieldValue(`user_groups.${index}.list_type`, value)}
              style={{ width: 160 }}
            >
              <Option value="allow_list">includes</Option>
              <Option value="deny_list">excludes</Option>
            </Select>
          )}
        </Field>
      )
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      render: (_, record, index) => (
        <TableActions
          actions={[
            {
              title: 'Remove',
              onClick: () => remove(index)
            }
          ]}
        />
      )
    }
  ];

  return (
    <div>
      <Table
        columns={tableColumns}
        dataSource={values.user_groups}
        rowKey={group => group.id || group._id}
        footer={() => (
          <>
            <label htmlFor="add_upload">
              <Button
                size="large"
                onClick={() => document.getElementById('add_upload').click()}
                style={{ marginRight: 16 }}
              >
                Add Upload
              </Button>
            </label>
            <Text type="secondary">
              Accepted Format: .xlsx/.csv files only.{' '}
              <a href={getFileUploadSampleLink('upload_users', tenantName)}>Download sample</a>
            </Text>
            <input
              id="add_upload"
              name="add_upload"
              className="hidden"
              type="file"
              onChange={e => {
                e.preventDefault();
                const files = Array.from(e.target.files);
                if (handleFileUpload) {
                  handleFileUpload(files);
                }
              }}
              data-testid="include-audience-file"
            />
          </>
        )}
      />
    </div>
  );
};

export default AudienceSetup;
