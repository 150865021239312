import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Card, Empty, Spin } from 'antd';
import { Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import DatePartSelect, { DatePart } from 'containers/Analytics/components/DatePartSelect';
import GraphCardMenu from 'containers/Analytics/components/GraphCardMenu';
import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import { abbreviateNumber, getDatePart, showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import LoyaltyTooltip from './LoyaltyTooltip';
import { loyaltyPointsOverTime } from './dummyData';

const IDENTIFIER = 'loyalty_flat_breakdown_by_date_point';

interface IProps {
  filterParams: {
    loyalty_program_id: Number;
  };
}

const LoyaltiesPointsOvertimeGraph: React.FC<IProps> = ({ filterParams }) => {
  const [activeLegend, setActiveLegend] = useState(null);
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const { loyalty_program_id } = filterParams;
  const dataParams = useMemo(
    () => ({ datepart, loyalty_program_id }),
    [datepart, loyalty_program_id]
  );
  const { card_id: questionId } = useCard(IDENTIFIER);
  const { data, tokenError, token, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );
  const hasError = tokenError || dataError;
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  let d;
  if (isDemoMode) {
    d = loyaltyPointsOverTime;
  } else {
    d = data?.data?.rows.map((row: Array<[number, number, string]>) => ({
      point_earned: row[0],
      point_burned: row[1],
      time: row[2]
    }));
  }

  const onMouseEnter = useCallback(legend => {
    const { dataKey } = legend;
    setActiveLegend(dataKey);
  }, []);

  const onMouseLeave = useCallback(_ => {
    setActiveLegend(null);
  }, []);

  const onDatepartChange = useCallback((val: DatePart) => setDatepart(val), []);
  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
  }, [diff]);

  const renderLegend = (value: string) => {
    return <span style={{ fontSize: 12, color: '#8c8c8c', cursor: 'pointer' }}>{value}</span>;
  };

  const renderChart = () => {
    let chart;
    if (!_isEmpty(d)) {
      chart = (
        <LineChart data={d}>
          <CartesianGrid
            vertical={false}
            strokeDasharray="0 0"
            fillOpacity="0.5"
            stroke="#f5f5f5"
          />
          <XAxis
            dataKey="time"
            stroke="#9b9b9b"
            padding={{ left: 16, right: 16 }}
            tick={{ strokeWidth: 1, fontSize: 12, fill: '#9b9b9', dy: 16 }}
            tickSize={3}
            tickFormatter={(tick: string) => moment(tick).format('MMM D')}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#d9d9d9', fontSize: 12, textAnchor: 'middle' }}
            tickFormatter={(tick: number) => abbreviateNumber(tick)}
            label={{
              value: 'Number of points',
              angle: -90,
              fill: '#595959',
              fontSize: 11,
              fillOpacity: 0.5,
              dx: -24
            }}
          />
          <Tooltip
            animationEasing="ease-in-out"
            wrapperStyle={{
              boxShadow: '0px 0px 12px rgba(0,0,0,.08)',
              border: 'none',
              background: '#ffffff'
            }}
            content={<LoyaltyTooltip title="Points" />}
            formatter={value => value.toLocaleString()}
            cursor={{ stroke: '#8c8c8c', fillOpacity: 0.5, strokeWidth: 1 }}
            itemStyle={{ fontSize: 12 }}
            labelStyle={{ display: 'none', visibility: 'hidden', pointerEvents: 'none' }}
          />
          <Legend
            iconType="line"
            iconSize={11}
            height={36}
            wrapperStyle={{
              bottom: -16 // the wrapper is absolutely positioned, not entirely sure how would you manipulate negative space
            }}
            formatter={renderLegend}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          <Line
            name="Earned"
            type="monotone"
            dataKey="point_earned"
            stroke="#2665ee"
            strokeWidth={2}
            strokeOpacity={!activeLegend ? 1 : activeLegend === 'point_earned' ? 1 : 0.1}
          />
          <Line
            name="Burned"
            type="monotone"
            dataKey="point_burned"
            stroke="#ffc53d"
            strokeWidth={2}
            strokeOpacity={!activeLegend ? 1 : activeLegend === 'point_burned' ? 1 : 0.1}
          />
        </LineChart>
      );
    } else {
      chart = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return (
      <ResponsiveContainer debounce={500} height={232}>
        {chart}
      </ResponsiveContainer>
    );
  };

  if (!showCard(cards, IDENTIFIER) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title="Earned and Burned Points over Time"
        style={{ height: 424 }}
        bodyStyle={{ height: '100%' }}
        extra={<GraphCardMenu questionId={questionId} token={token} dataParams={dataParams} />}
      >
        <Row justify="end" align="middle" style={{ marginBottom: 16 }}>
          <DatePartSelect value={datepart} onChange={onDatepartChange} />
        </Row>

        {hasError && <Error retry={onRetry} />}
        {!isDemoMode && !d && !hasError && (
          <Row justify="center" align="middle" style={{ height: '250px' }}>
            <Spin size="large" />
          </Row>
        )}
        {((!hasError && !isDemoMode && d) || isDemoMode) && renderChart()}
      </Card>
    </GridItem>
  );
};

export default LoyaltiesPointsOvertimeGraph;
