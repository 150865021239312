import React from 'react';
import { Tag, TagProps } from 'antd';
import _startCase from 'lodash/startCase';

interface StatusTagProps extends TagProps {
  state: string;
}

export const StatusTag = ({ state = 'draft', style = {}, ...props }: StatusTagProps) => {
  let color = null;
  switch (state) {
    case 'draft':
    case 'processing':
      color = '#b9b9b9';
      break;
    case 'inactive':
    case 'failed':
    case 'fail':
    case 'expired':
      color = '#c23a34';
      break;
    case 'active':
    case 'success':
    case 'complete':
    case 'completed':
    case 'processed':
    case 'issued':
      color = '#10d994';
      break;
    case 'redeemed':
    case 'pending':
      color = '#f5a623';
      break;
    case 'approved':
    case 'scheduled':
      color = '#1295d3';
      break;
    case 'ended':
      color = '#e5e5e5';
      break;
    case 'running':
      color = '#469BFF';
      break;
    default:
      color = '#cccccc';
      break;
  }

  return (
    <Tag
      color={color}
      {...props}
      style={{
        borderRadius: '1.35rem',
        padding: '0 8px',
        lineHeight: '1.5rem',
        ...style
      }}
    >
      {_startCase(state)}
    </Tag>
  );
};

export default StatusTag;
