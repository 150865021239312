import useSWR from 'swr';

import { IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export interface ILabel {
  id: number;
  title: string;
  description: string;
  usage: string[];
  parent_id: null | number;
  count: number;
  children: ILabel[];
  usage_matched: boolean;
  updated_at: string;
}

export const useLabelList = (params?: object) => {
  const url = `/v4/dash/labels`;

  const { data, isLoading, mutate, error } = useSWR<IPaginatedResponse<ILabel>>(
    { url, params },
    fetcher
  );

  return { data: data?.data, isLoading, mutate, error };
};
