import React, { useCallback, memo } from 'react';
import { Menu, message } from 'antd';
import { data } from 'currency-codes';
import qs from 'qs';

import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAnalyticsContext } from 'containers/Analytics/hooks';
import { useAuthUser } from 'hooks';

import { METABASE_API_ROOT } from '../../../appConstants';

const GraphCardMenu: React.FC<{ questionId: number; token: string; dataParams: any }> = ({
  questionId,
  token,
  dataParams
}) => {
  const { email } = useAuthUser();
  const { isDemoMode, dateRange } = useAnalyticsContext();
  const onDownload = useCallback(
    (token, dateRange, dataParams) => () => {
      message.loading('The download should begin shortly …');
      const linkNode = document.createElement('a');
      linkNode.href = `${METABASE_API_ROOT}/api/embed/card/${token}/query/csv?${qs.stringify({
        start_date: dateRange[0].format('YYYY-MM-DD'),
        end_date: dateRange[1].format('YYYY-MM-DD'),
        ...dataParams
      })}`;
      linkNode.download = '';
      linkNode.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
      );
    },
    []
  );

  return (
    <ActionsMenu
      menu={
        <Menu>
          {/*{!isDemoMode && (*/}
          {/*  <Menu.Item key="download-report">*/}
          {/*    <a onClick={onDownload(token, dateRange, dataParams)}>Download Report</a>*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}
          {email && email.endsWith('@perxtech.com') && (
            <Menu.Item key="debug">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                View Metabase Card
              </a>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default memo(GraphCardMenu);
