import React, { useCallback, useMemo } from 'react';
import HeatMap from 'react-heatmap-grid';
import { Card, Empty, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import { GridItem } from 'components/Display/GridRenderer';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import {
  GraphContent,
  CardTitle,
  GraphHeader,
  GraphHeaderExtra
} from 'containers/Analytics/styles';
import { colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardExtra from '../../components/GraphCardExtra';
import { getHeatMapData } from './dummyData';

const color = colorGenerator().next().value;

interface HeatMapGraphProps {
  identifier: string;
  title: string;
  helpText?: string;
  filterParams?: any;
  isEngagement?: boolean;
}

const HeatMapGraph = ({
  identifier,
  title,
  filterParams,
  helpText,
  isEngagement = false
}: HeatMapGraphProps) => {
  const dataParams = useMemo(
    () => ({ ...filterParams, ...(isEngagement ? { is_engagement: 'true' } : {}) }),
    [filterParams]
  );

  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, dataRevalidate, isDataValidating, token } = useMetabase(
    questionId,
    dataParams
  );
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    dataRevalidate();
  }, [dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <Graph data={data?.data?.rows} title={title} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem occupies={2}>
      <Card>
        <GraphHeader>
          <CardTitle>{title}</CardTitle>
          <GraphHeaderExtra>
            <GraphCardExtra
              questionId={questionId}
              token={token}
              dateRange={dateRange}
              dataParams={dataParams}
              helpText={helpText}
            />
          </GraphHeaderExtra>
        </GraphHeader>
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Graph = ({ data, title }) => {
  const { isDemoMode } = useAnalyticsContext();
  if (_.isEmpty(data) && !isDemoMode) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  let graphData = data;
  if (isDemoMode) {
    graphData = getHeatMapData();
  }

  const xLabels = Array(24)
    .fill(0)
    .map((i, index) => index);
  const yLabels = WEEKDAYS;

  const days = WEEKDAYS.length;
  const hours = 24;
  const byDay = _.groupBy(graphData, 1);

  let processedData = [];

  //For each day,
  for (let day = 0; day < days; day++) {
    //if day data exists, process
    if (byDay[day]) {
      processedData[day] = [];
      const processedDay = processedData[day];
      const dayData = byDay[day];
      const byHour = _.groupBy(dayData, '2');

      //For each hour in day
      for (let hour = 0; hour < hours; hour++) {
        if (byHour[hour]) {
          //if data for hour exists
          processedDay.push(byHour[hour][0][0]);
        } else {
          //if data for hour missing, add entry
          processedDay.push(0);
        }
      }
    } else {
      //if day data does not exists, create entries
      processedData[day] = Array(hours).fill(0);
    }
  }

  return (
    <HeatMapWrapper>
      <HeatMap
        xLabels={xLabels}
        xLabelsLocation="bottom"
        yLabels={yLabels}
        data={processedData}
        height={25}
        cellStyle={(background, value, min, max, data, x, y) => ({
          background: color,
          borderRadius: '4px',
          // minWidth: '4rem',
          height: '1.5rem',
          margin: '4px',
          opacity: `${1 - (max - (value ? value : 0)) / (max - min)}`
        })}
        cellRender={value => (
          <Tooltip title={!_.isNil(value) ? value.toLocaleString() : ''} mouseLeaveDelay={0}>
            <HeatMapCell>
              <HeatMapCellText>{!_.isNil(value) ? value : ''}</HeatMapCellText>
            </HeatMapCell>
          </Tooltip>
        )}
        title={() => ''}
      />
      <LegendContainer>
        <LegendBar />
        <LegendLabels>
          <LabelLeft>Low</LabelLeft>
          <LabelRight>High</LabelRight>
        </LegendLabels>
      </LegendContainer>
    </HeatMapWrapper>
  );
};

const HeatMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 80%;
  justify-content: center;
  max-width: 100%;

  > div {
    max-width: 100%;
  }
`;

const HeatMapCell = styled.div`
  color: transparent;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  font-size: 12;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  user-select: none; /* DATA-404 - https://caniuse.com/#feat=user-select-none */
  &:hover {
    color: #11111;
    opacity: 1;
  }
`;

const HeatMapCellText = styled.div`
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 1rem;
`;

const LegendBar = styled.div`
  background: linear-gradient(to right, #ffffff, ${color});
  height: 12px;
  width: 100%;
  border-radius: 4px;
`;

const LegendLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelRight = styled.span``;

const LabelLeft = styled.span``;

export default HeatMapGraph;
