import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import _debounce from 'lodash/debounce';
import _differenceWith from 'lodash/differenceWith';
import _isNil from 'lodash/isNil';

import { IMerchant, useMerchantList } from 'api/merchants';
import API from 'utils/request';

const fetchMerchants = async (merchantIds: number[], params: {}) => {
  return await Promise.all(
    merchantIds.map(async (id: number) => {
      const res = await API.get(`/v4/dash/merchants/${id}`, params);
      return res.data.data;
    })
  );
};

export const MerchantSelect = ({
  mode = null,
  placeholder = 'Please select a merchant',
  preSelected = false,
  value,
  ...props
}) => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [preselectedItem, setPreselectedItem] = useState<IMerchant[]>([]);
  const [items, setItems] = useState<IMerchant[]>([]);
  const [page, setPage] = useState<number>(1);

  const { data, meta, isLoading, error } = useMerchantList({
    search_string: searchString,
    state: 'active',
    page
  });

  const onSearch = useCallback(val => {
    setItems([]);
    setSearchString(val);
  }, []);
  const onSelect = useCallback(() => setSearchString(null), []);

  useEffect(() => {
    if (data?.length) {
      setItems(oldItems => [...oldItems, ...data]);
    }
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();

    async function getMerchantObjects(value) {
      let values: number[] = Array.isArray(value)
        ? value
        : _isNil(value) || value === ''
        ? []
        : [value];

      // get from the current list of merchants, merchants which are selected and ids of
      // merchants for which we do not have definition but which are selected
      const merchants: IMerchant[] = [];
      const missingIds: number[] = [];
      for (const id of values) {
        let merch: IMerchant | undefined =
          items.find(m => m.id === id) || preselectedItem.find(m => m.id === id);
        if (merch) {
          merchants.push(merch);
        } else {
          // if we could not find it, add it to the list of merchants to be fetched
          missingIds.push(id);
        }
      }
      // fetch missing merchants
      const missingMerchants =
        missingIds.length > 0
          ? await fetchMerchants(missingIds, { signal: controller.signal })
          : [];

      // set preselected list
      setPreselectedItem([...merchants, ...missingMerchants]);
    }

    getMerchantObjects(value);

    return () => {
      setPreselectedItem([]);
      controller.abort();
    };
  }, [value, items]);

  const handleScroll = e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    const totalPage = meta?.total_pages || 0;
    if (!isLoading && scrollTop + offsetHeight >= scrollHeight - 500 && page < totalPage) {
      setPage(value => value + 1);
    }
  };

  if (error) {
    return null;
  }

  return (
    <Select
      showSearch
      value={value}
      data-testid="merchant-select"
      loading={isLoading}
      placeholder={isLoading ? 'Loading...' : placeholder}
      style={{ width: '100%' }}
      mode={mode}
      onSearch={_debounce(onSearch, 400)}
      onSelect={onSelect}
      onPopupScroll={handleScroll}
      {...props}
      onBlur={e => {
        setSearchString(null);
        e.preventDefault();
      }}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
    >
      {preselectedItem.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name} (ID: {item.id})
        </Select.Option>
      ))}
      {_differenceWith(items, preselectedItem, (x, y) => x.id === y.id).map(option => (
        <Select.Option key={option.id} value={option.id}>
          {option.name} (ID: {option.id})
        </Select.Option>
      ))}
    </Select>
  );
};

export default MerchantSelect;
