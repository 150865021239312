import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';

import { TitleDropdown } from 'containers/Analytics/components/DatePartSelect';
import Error from 'containers/Analytics/components/Error';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import ComboGraph from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import AreaGraph from 'containers/Analytics/pages/graphs/GraphOnly/AreaGraph';
import { getDataAndLabels } from 'containers/Analytics/pages/graphs/UserGraphs/ReturningUsers';
import { showCard } from 'containers/Analytics/utils';

import getDemoDataAndLabels from './demographicsData';

interface DemographicsGraphProps {
  identifierOptionsMapping?: any;
  filterParams?: any;
}

const DemographicsGraph = ({ identifierOptionsMapping, filterParams }: DemographicsGraphProps) => {
  const [selectedOption, setSelectedOption] = useState('age');
  const [identifier, setIdentifier] = useState(
    identifierOptionsMapping?.[Object.keys(identifierOptionsMapping)?.[0]]
  );
  const { card_id: questionId } = useCard(identifier);
  const { isDemoMode, cards, datePart, dateRange } = useAnalyticsContext();

  const dataParams = useMemo(
    () => ({
      ...filterParams,
      datepart: datePart
    }),
    [filterParams, datePart]
  );

  const {
    data,
    dataError,
    tokenError,
    tokenRevalidate,
    dataRevalidate,
    isDataValidating: loading
  } = useMetabase(questionId, dataParams);

  useEffect(() => {
    setIdentifier(identifierOptionsMapping[selectedOption]);
  }, [selectedOption]);

  // Live data handling START
  const d = data?.data;
  const { data: graphData, labels } = useMemo(() => {
    return isDemoMode
      ? getDemoDataAndLabels(dateRange, datePart, selectedOption)
      : getDataAndLabels(d, datePart);
  }, [dateRange, datePart, selectedOption, d, isDemoMode]);

  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);
  // Live data handling END

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <ComboGraph
      occupies={1}
      title={
        <>
          Demographics by{' '}
          <TitleDropdown
            onSelect={setSelectedOption}
            value={selectedOption}
            dropdownMatchSelectWidth={false}
          >
            {Object.keys(identifierOptionsMapping).map(key => {
              return (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              );
            })}
          </TitleDropdown>
        </>
      }
      sections={[
        ...(hasError
          ? [<Error retry={onRetry} />]
          : [<AreaGraph graphData={loading ? null : graphData} labels={labels} />])
      ]}
    />
  );
};

export default DemographicsGraph;
