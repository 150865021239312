import React, { useMemo } from 'react';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import moment from 'moment';

import { useBadge } from 'api/badges';
import { useCampaign } from 'api/campaigns';
import { useLeaderboard } from 'api/leaderboards';
import { useLoyaltyProgram } from 'api/loyalty';
import {
  useCityList,
  useCustomPropertyList,
  useEthnicityList,
  useGenderList,
  useStateList
} from 'api/mappings';
import { useReward } from 'api/rewards';
import { useSimpleLoyaltyList } from 'api/simple';
import { DATE_FORMAT } from 'appConstants';
import Label from 'components/Text/Label';
import { customFilterTemplates } from 'containers/Audience/form/steps/Audience/components/Personal';
import { useFeatureFlags } from 'hooks';
import { doesFilterHaveValue } from 'utils/audience';

import { Subtitle, Section, FilterList } from './AudienceInfo.styles';
import { groupFilters } from './groupFilters';

const filterfields = {
  loyalty_tier: 'Loyalty',
  gender: 'Gender',
  age: 'Age',
  birthmonth: 'Birth Month',
  race: 'Ethnicity',
  city: 'City',
  states: 'States',
  offer_id: 'Offer Id',
  primary_cluster_comb: 'Primary Cluster Comb',
  primary_cluster_state: 'Primary Cluster State',
  membership: 'Member Of',
  current_birthday: 'Only During Day Of Birth',
  current_birthmonth: 'Only During Month Of Birth',
  has_birthday: 'On Birthday',
  user_type: 'User Type',
  email_tags: 'Email Tags',
  account_confirmed_at: 'Signed up between',
  earned_rewards: 'Reward',
  earned_badges: 'Badge',
  loyalty_points_balance: 'Points balance',
  campaign_enrolment: 'Campaign',
  leaderboard_position: 'Leaderboard position',
  is_upi_enabled: 'UPI',
  is_scan_pay_enabled: 'Scan pay',
  is_bbps_enabled: 'BBPS',
  is_kyc_complete: 'KYC',
  is_current_address_present: 'Current address',
  payment_status: 'Payment status',
  days_past_dues: 'Days past dues',
  bnpl_unpaid_emi_date: 'BNPL Unpaid EMI Date',
  instaloan_unpaid_emi_date: 'Instaloan Unpaid EMI Date'
};

const FILTER_TYPES = {
  age: 'age',
  birthmonth: 'birthmonth',
  gender: 'gender',
  loyalty_tier: 'loyalty_tier',
  race: 'race',
  city: 'city',
  states: 'states',
  state: 'state',
  offer_id: 'offer_id',
  primary_cluster_comb: 'primary_cluster_comb',
  primary_cluster_state: 'primary_cluster_state',
  membership: 'membership',
  has_birthday: 'has_birthday',
  current_birthday: 'current_birthday',
  current_birthmonth: 'current_birthmonth',
  user_type: 'user_type',
  email_tags: 'email_tags',
  account_confirmed_at: 'account_confirmed_at',
  earned_rewards: 'earned_rewards',
  earned_badges: 'earned_badges',
  loyalty_points_balance: 'loyalty_points_balance',
  campaign_enrolment: 'campaign_enrolment',
  leaderboard_position: 'leaderboard_position',
  is_upi_enabled: 'is_upi_enabled',
  is_scan_pay_enabled: 'is_scan_pay_enabled',
  is_bbps_enabled: 'is_bbps_enabled',
  is_kyc_complete: 'is_kyc_complete',
  is_current_address_present: 'is_current_address_present',
  payment_status: 'payment_status',
  days_past_dues: 'days_past_dues',
  bnpl_unpaid_emi_date: 'bnpl_unpaid_emi_date',
  instaloan_unpaid_emi_date: 'instaloan_unpaid_emi_date'
};

const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

const paymentStatusOptions = {
  UPCOMING: 'Upcoming',
  DUE_NOW: 'Due now',
  FUTURE: 'Future',
  OVERDUE: 'Overdue',
  NONE: 'None'
};

const BadgeInfo = ({ filterData: { eq } }) => {
  const { data } = useBadge(eq);
  return (
    <>
      {data?.internal_name} (ID: {data?.id})
    </>
  );
};
const CampaignInfo = ({ filterData: { eq } }) => {
  const { data } = useCampaign(eq);
  return (
    <>
      {data?.name_en} (ID: {data?.id})
    </>
  );
};
const LeaderboardInfo = ({ filterData: { eq, gteq = '-', lteq = '-' } }) => {
  const { data } = useLeaderboard(eq);
  return (
    <>
      {data?.internal_name} (ID: {data?.id}) — positions from {gteq} to {lteq}
    </>
  );
};
const RewardInfo = ({ filterData: { eq } }) => {
  const { data } = useReward(eq);
  return (
    <>
      {data?.name} (ID: {data?.id})
    </>
  );
};
const LoyaltyPointsInfo = ({ filterData: { eq, gteq = '-', lteq = '-' } }) => {
  const { data } = useLoyaltyProgram(eq);
  return (
    <>
      {data?.name} (ID: {data?.id}) — points amount from {gteq} to {lteq}
    </>
  );
};

const renderFilterContent = (key, formatFiltersList) => {
  const {
    ages,
    birthmonth,
    gender,
    loyalty_tier,
    race,
    city,
    states,
    offer_id,
    primary_cluster_comb,
    primary_cluster_state,
    membership,
    has_birthday,
    current_birthday,
    current_birthmonth,
    user_type,
    email_tags,
    account_confirmed_at
  } = formatFiltersList;

  let content;
  switch (key) {
    case FILTER_TYPES.age: {
      content = ages.join(', ');
      break;
    }
    case FILTER_TYPES.gender: {
      content = gender;
      break;
    }
    case FILTER_TYPES.birthmonth: {
      content = birthmonth.join(', ');
      break;
    }
    case FILTER_TYPES.race: {
      content = race.join(', ');
      break;
    }
    case FILTER_TYPES.city: {
      content = city.join(', ');
      break;
    }
    case FILTER_TYPES.states: {
      content = states.join(', ');
      break;
    }
    case FILTER_TYPES.offer_id: {
      content = offer_id.join(', ');
      break;
    }
    case FILTER_TYPES.loyalty_tier: {
      content = loyalty_tier.join(', ');
      break;
    }
    case FILTER_TYPES.primary_cluster_comb: {
      content = primary_cluster_comb.join(', ');
      break;
    }
    case FILTER_TYPES.primary_cluster_state: {
      content = primary_cluster_state.join(', ');
      break;
    }
    case FILTER_TYPES.membership: {
      content = membership;
      break;
    }
    case FILTER_TYPES.has_birthday: {
      content = has_birthday ? 'True' : 'False';
      break;
    }
    case FILTER_TYPES.current_birthday: {
      content = current_birthday ? 'True' : 'False';
      break;
    }
    case FILTER_TYPES.current_birthmonth: {
      content = current_birthmonth ? 'True' : 'False';
      break;
    }
    case FILTER_TYPES.user_type: {
      content = user_type.join(', ');
      break;
    }
    case FILTER_TYPES.email_tags: {
      content = email_tags.join(', ');
      break;
    }
    case FILTER_TYPES.account_confirmed_at: {
      const from = moment(account_confirmed_at.gteq).format(DATE_FORMAT);
      const to = moment(account_confirmed_at.lteq).format(DATE_FORMAT);
      content = `${from} and ${to}`;
      break;
    }
    case FILTER_TYPES.earned_badges: {
      content = <BadgeInfo filterData={formatFiltersList.earned_badges} />;
      break;
    }
    case FILTER_TYPES.campaign_enrolment: {
      content = <CampaignInfo filterData={formatFiltersList.campaign_enrolment} />;
      break;
    }
    case FILTER_TYPES.earned_rewards: {
      content = <RewardInfo filterData={formatFiltersList.earned_rewards} />;
      break;
    }
    case FILTER_TYPES.leaderboard_position: {
      content = <LeaderboardInfo filterData={formatFiltersList.leaderboard_position} />;
      break;
    }
    case FILTER_TYPES.loyalty_points_balance: {
      content = <LoyaltyPointsInfo filterData={formatFiltersList.loyalty_points_balance} />;
      break;
    }
    case FILTER_TYPES.is_upi_enabled:
      content = formatFiltersList.is_upi_enabled ? 'Enabled' : 'Disabled';
      break;
    case FILTER_TYPES.is_scan_pay_enabled:
      content = formatFiltersList.is_scan_pay_enabled ? 'Enabled' : 'Disabled';
      break;
    case FILTER_TYPES.is_bbps_enabled:
      content = formatFiltersList.is_bbps_enabled ? 'Enabled' : 'Disabled';
      break;
    case FILTER_TYPES.is_kyc_complete:
      content = formatFiltersList.is_kyc_complete ? 'Complete' : 'Incomplete';
      break;
    case FILTER_TYPES.is_current_address_present:
      content = formatFiltersList.is_current_address_present ? 'Enabled' : 'Disabled';
      break;
    case FILTER_TYPES.payment_status:
      content = formatFiltersList.payment_status?.eq
        ?.map(key => paymentStatusOptions[key])
        .join(', ');
      break;
    case FILTER_TYPES.days_past_dues:
      content = [formatFiltersList.days_past_dues.gteq, formatFiltersList.days_past_dues.lteq]
        .filter(Boolean)
        .join(' to ');
      break;
    case FILTER_TYPES.bnpl_unpaid_emi_date:
      content = [
        formatFiltersList.bnpl_unpaid_emi_date.gteq,
        formatFiltersList.bnpl_unpaid_emi_date.lteq
      ]
        .filter(Boolean)
        .join(' to ');
      break;
    case FILTER_TYPES.instaloan_unpaid_emi_date:
      content = [
        formatFiltersList.instaloan_unpaid_emi_date.gteq,
        formatFiltersList.instaloan_unpaid_emi_date.lteq
      ]
        .filter(Boolean)
        .join(' to ');
      break;
    default:
      break;
  }

  return <Label nomargin>{content}</Label>;
};

const renderCustomAttributes = (attributes = [], type) => {
  return attributes?.[0]?.criteria?.map((criteria, index) => {
    const attributeMapping = customFilterTemplates.find(mapping => mapping.type === criteria?.type);

    const operatorMapping = attributeMapping?.operators.find(
      operatorMapping => operatorMapping.key === criteria?.operator
    );

    const operatorLabel = operatorMapping?.label || criteria?.label;

    let value = null;
    if (operatorMapping?.type === 'select') {
      value = operatorMapping?.values?.find(
        option => option.key === criteria?.selected_values
      )?.label;
    } else if (operatorMapping?.type === 'multi_select') {
      value = criteria?.selected_values
        ?.map(value => {
          return operatorMapping?.values?.find(option => option.key === value)?.label;
        })
        ?.join(', ');
    } else {
      console.log(2, { criteria });
      value = String(criteria?.selected_values);
    }

    return (
      <div key={criteria?.attribute}>
        <span>
          {type === 'include' && index !== 0 && attributes?.[0]?.operator && (
            <b>{attributes?.[0]?.operator?.toLowerCase() + ' '}</b>
          )}
        </span>
        {criteria?.attribute} <strong>{operatorLabel}</strong> {value}
      </div>
    );
  });
};

const AudienceFilters: React.FC<{ data: any }> = ({ data }) => {
  const { data: genders = [] } = useGenderList();
  const { data: races = [] } = useEthnicityList();
  const { data: cities = [] } = useCityList();
  const { data: states = [] } = useStateList();
  const { data: custom = [] } = useCustomPropertyList();
  const { data: loyalties = [] } = useSimpleLoyaltyList();

  const { audience_email_tags: audienceEmailTagsEnabled } = useFeatureFlags();

  const filtersMap = useMemo(() => {
    let filters = {};
    let filtersMap = new Map();

    if (data && 'filters' in data) {
      filters = data.filters;
    }

    // We don't need to show empty filters in AudienceInfo.
    // 'personal' and 'location' has subfilter.
    filters = _pickBy(filters, doesFilterHaveValue);

    filtersMap = groupFilters(filters);

    return filtersMap;
  }, [data]);

  const formatFilterData = filters => {
    const birthmonth = _get(filters, 'birthmonth[0].criteria[0].values', []).map(
      month => MONTHS[month]
    );

    const race = _get(filters, 'race[0].criteria[0].values', []).map(race => {
      return races.find(r => r.id === race)?.name;
    });
    const city = _get(filters, 'city[0].criteria[0].values', []).map(city => {
      return cities.find(c => c.id === city)?.name;
    });

    const offer_id = _get(filters, 'offer_id[0].criteria[0].values', []);
    const primary_cluster_comb = _get(filters, 'primary_cluster_comb[0].criteria[0].values', []);

    const primary_cluster_state = _get(filters, 'primary_cluster_state[0].criteria[0].values', []);
    const user_type = _get(filters, 'user_type[0].criteria[0].values', []);

    const selectedStates = _get(filters, 'states[0].criteria[0].values', []).map(state => {
      return states.find(s => s.id === state)?.name;
    });
    const raw_ages = filters?.age || [];
    const ages = raw_ages.map(item => `from ${item.criteria.gteq} to ${item.criteria.lteq}`);
    const gender = genders.find(
      r => r.id === _get(filters, 'gender[0].criteria[0].value', null)
    )?.name;
    const tiers_list = loyalties.reduce(
      (acc, program) => [
        ...acc,
        ...program.tiers.map(tier => ({ ...tier, name: `${tier.name} (${program.name})` }))
      ],
      []
    );
    const tiers_list_ids = _get(filters, 'loyalty_tier.criteria.loyalty_tier_ids', []);
    const loyalty_tier = tiers_list_ids.map(id => {
      const tier = tiers_list.find(item => item.id === parseInt(id, 0)) || {};
      return tier.name;
    });
    const membership = _get(filters, 'membership[0].criteria.eq', '');
    const has_birthday = _get(filters, 'has_birthday[0].criteria[0].values', null);
    const current_birthday = _get(filters, 'current_birthday[0].criteria[0].values', null);
    const current_birthmonth = _get(filters, 'current_birthmonth[0].criteria[0].values', null);
    const email_tags = _get(filters, 'email_tags.criteria.json_values', []);

    const loyalty_points_balance = _get(filters, 'loyalty_points_balance.criteria');
    const account_confirmed_at = _get(filters, 'account_confirmed_at[0].criteria');
    const earned_rewards = _get(filters, 'earned_rewards[0].criteria');
    const earned_badges = _get(filters, 'earned_badges[0].criteria');
    const campaign_enrolment = _get(filters, 'campaign_enrolment[0].criteria');
    const leaderboard_position = _get(filters, 'leaderboard_position[0].criteria');

    const is_upi_enabled = _get(filters, 'is_upi_enabled[0].criteria.eq') === 'true';
    const is_scan_pay_enabled = _get(filters, 'is_scan_pay_enabled[0].criteria.eq') === 'true';
    const is_bbps_enabled = _get(filters, 'is_bbps_enabled[0].criteria.eq') === 'true';
    const is_kyc_complete = _get(filters, 'is_kyc_complete[0].criteria.eq') === 'true';
    const is_current_address_present =
      _get(filters, 'is_current_address_present[0].criteria.eq') === 'true';
    const payment_status = _get(filters, 'payment_status[0].criteria');
    const days_past_dues = _get(filters, 'days_past_dues[0].criteria');
    const bnpl_unpaid_emi_date = _get(filters, 'bnpl_unpaid_emi_date[0].criteria');
    const instaloan_unpaid_emi_date = _get(filters, 'instaloan_unpaid_emi_date[0].criteria');

    return {
      ages,
      gender,
      loyalty_tier,
      birthmonth,
      race,
      city,
      offer_id,
      states: selectedStates,
      primary_cluster_comb,
      primary_cluster_state,
      membership,
      has_birthday,
      current_birthday,
      current_birthmonth,
      user_type,
      email_tags,
      account_confirmed_at,
      earned_rewards,
      earned_badges,
      loyalty_points_balance,
      campaign_enrolment,
      leaderboard_position,
      is_upi_enabled,
      is_scan_pay_enabled,
      is_bbps_enabled,
      is_kyc_complete,
      is_current_address_present,
      payment_status,
      days_past_dues,
      bnpl_unpaid_emi_date,
      instaloan_unpaid_emi_date
    };
  };

  const renderFilterList = (subtitle, filtersList) => {
    const formatFiltersList = formatFilterData(filtersList);

    const arrayFilters = Object.keys(filtersList).map(k => {
      let filterType = k;
      if (filterType === 'state') filterType = 'states'; // API result inconsisteny; just let it go for now
      if (filterType === 'custom_attributes') return null;
      if (!audienceEmailTagsEnabled && filterType === 'email_tags') return null;
      const customFieldData = custom.find(item => item.key === k);
      const isCustomField = !!customFieldData;

      let label, valueToRender;

      if (isCustomField) {
        const c = customFieldData;
        label = c.label;

        if (c.selectors === 'range') {
          let from, to;

          from = _get(data?.filters, `${c.key}[0].criteria[0].gteq`);
          to = _get(data?.filters, `${c.key}[0].criteria[0].lteq`);

          if (c.type === 'date') {
            from = moment(from).format(DATE_FORMAT);
            to = moment(to).format(DATE_FORMAT);
          }

          valueToRender = `${from} – ${to}`;
        } else {
          valueToRender = _get(data?.filters, `${c.key}[0].criteria[0].values`)
            ?.map(id => c?.mappings?.find(m => m.id === id)?.name)
            .join(', ');
        }
      } else {
        label = filterfields[filterType];
        // TODO. Refactor this logic
        valueToRender = renderFilterContent(filterType, formatFiltersList);
      }

      return (
        <div key={k}>
          <Label dark bold>
            {label}:
          </Label>
          {valueToRender}
        </div>
      );
    });

    return (
      <>
        <Subtitle>{subtitle}</Subtitle>
        <FilterList>{arrayFilters}</FilterList>
      </>
    );
  };

  if (filtersMap.size > 0 || data?.filters?.custom_attributes?.length > 0) {
    return (
      <>
        <Section>
          {filtersMap.has('include') && renderFilterList('include', filtersMap.get('include'))}
          {renderCustomAttributes(data?.filters?.custom_attributes, data.include_opt_audience_list)}
        </Section>
        <Section>
          {filtersMap.has('exclude') && renderFilterList('exclude', filtersMap.get('exclude'))}
        </Section>
      </>
    );
  }
  return <>No filters selected</>;
};

export default AudienceFilters;
