import { message, notification } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAIL,
  USER_EXTEND_SESSION,
  USER_EXTEND_SESSION_SUCCESS,
  USER_EXTEND_SESSION_FAIL,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_USER_FAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_FAIL,
  VALIDATE_RESET_PASSWORD_SUCCESS,
  VALIDATE_INIT_CHANGE_PASSWORD,
  VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS,
  VALIDATE_INIT_CHANGE_PASSWORD_FAIL,
  INIT_CHANGE_PASSWORD,
  INIT_CHANGE_PASSWORD_SUCCESS,
  INIT_CHANGE_PASSWORD_PASSWORD_FAIL,
  GET_NECESSITIES,
  GET_NECESSITIES_SUCCESS,
  GET_NECESSITIES_FAIL,
  CHANGE_APP_LOCALE,
  OTP_REQUIRED,
  GET_CUSTOMER_PENDING_REQUEST,
  GET_CUSTOMER_PENDING_REQUEST_SUCCESS,
  GET_CUSTOMER_PENDING_REQUEST_FAIL,
  GET_CAMPAIGN_PENDING_REQUEST,
  GET_CAMPAIGN_PENDING_REQUEST_SUCCESS,
  GET_CAMPAIGN_PENDING_REQUEST_FAIL,
  GET_REWARD_PENDING_REQUEST,
  GET_REWARD_PENDING_REQUEST_SUCCESS,
  GET_REWARD_PENDING_REQUEST_FAIL,
  GET_RULE_PENDING_REQUEST,
  GET_RULE_PENDING_REQUEST_SUCCESS,
  GET_RULE_PENDING_REQUEST_FAIL
} from './constants';

export const userSignIn = (payload, history, location, resetSubmitting, method = 'password') => {
  return {
    type: SIGNIN_USER,
    endpoint: `/v4/dash/user_sessions`,
    noAuth: true,
    method: method,
    types: [SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNIN_USER_FAIL, OTP_REQUIRED],
    notification: {
      loading: () => {
        // message.loading('Logging in...', DURATION_TIMER);
      },
      success: () => {
        message.success('Login Success');
      },
      error: e => {
        message
          .error(e && e.message ? e.message : 'Please try again later.', DURATION_TIMER)
          .then(_ => {
            if (e?.code === 403 && e?.message.includes('expired')) {
              window.location.pathname = `/reset_password`;
            }
          }, null);
        resetSubmitting(false);
      }
    },
    // nextAction: { type: GET_AUTHORIZATION },
    formdata: payload,
    history,
    location
  };
};

export const userExtend = payload => {
  return {
    type: USER_EXTEND_SESSION,
    endpoint: `/v4/dash/user_sessions/extend`,
    noAuth: true,
    method: 'post',
    types: [USER_EXTEND_SESSION, USER_EXTEND_SESSION_SUCCESS, USER_EXTEND_SESSION_FAIL],
    data: payload,
    notification: {
      error: e => {
        // message.error('Your session has expired');
      }
    }
  };
};

export const userSignOut = (history, params = { urlParams: {} }) => ({
  type: SIGNOUT_USER,
  history,
  urlParams: params?.urlParams
});
export const userSignOutSuccess = payload => ({ type: SIGNOUT_USER_SUCCESS, payload });
export const userSignOutFail = error => ({ type: SIGNOUT_USER_FAIL, error });

export const forgetPassword = payload => {
  return {
    type: FORGET_PASSWORD,
    endpoint: `/v4/dash/user_sessions/forget_password`,
    noAuth: true,
    method: 'post',
    types: [FORGET_PASSWORD, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_FAIL],
    notification: {
      loading: () => {
        message.loading('Submitting...', DURATION_TIMER);
      },
      success: () =>
        notification['success']({
          message: 'Info',
          description: `Your reset password email link has been sent out, please check your email again`
        }),
      error: e => {
        notification['error']({
          message: 'Error',
          description: e.message
        });
      }
    },
    formdata: payload
  };
  // return {
  //   type: FORGET_PASSWORD,
  //   payload,
  // }
};

export const resetPassword = payload => {
  return {
    type: RESET_PASSWORD,
    endpoint: `/v4/dash/user_sessions/reset_password`,
    noAuth: true,
    method: 'post',
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    notification: {
      loading: () => {
        message.loading('Submitting...', DURATION_TIMER);
      },
      success: () =>
        notification['success']({
          message: 'Info',
          description: 'Reset password Success. You may now login again'
        }),
      error: e => {
        message.error(e.message, DURATION_TIMER);
      }
    },
    formdata: payload
  };
};

export const validateResetPassword = payload => {
  return {
    type: VALIDATE_RESET_PASSWORD,
    endpoint: `/v4/dash/user_sessions/validate_reset_password_token`,
    noAuth: true,
    types: [VALIDATE_RESET_PASSWORD, VALIDATE_RESET_PASSWORD_SUCCESS, VALIDATE_RESET_PASSWORD_FAIL],
    notification: {
      loading: () => {
        message.loading('Loading...', DURATION_TIMER);
      },
      success: () => {
        message.success('Success', DURATION_TIMER);
      },
      error: () => {
        message.error('Invalid Token', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const validateFirstTimeChangepassword = payload => {
  return {
    type: VALIDATE_INIT_CHANGE_PASSWORD,
    endpoint: `/v4/dash/invitation/accept/`,
    noAuth: true,
    types: [
      VALIDATE_INIT_CHANGE_PASSWORD,
      VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS,
      VALIDATE_INIT_CHANGE_PASSWORD_FAIL
    ],
    notification: {
      loading: () => {
        message.loading('Loading...', DURATION_TIMER);
      },
      success: () => {
        message.success('Success', DURATION_TIMER);
      },
      error: () => {
        message.error('Invalid Token', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const initChangePassword = payload => {
  return {
    type: INIT_CHANGE_PASSWORD,
    endpoint: `/v4/dash/invitation/`,
    method: `put`,
    noAuth: true,
    types: [INIT_CHANGE_PASSWORD, INIT_CHANGE_PASSWORD_SUCCESS, INIT_CHANGE_PASSWORD_PASSWORD_FAIL],
    notification: {
      loading: () => {
        message.loading('Submitting...', DURATION_TIMER);
      },
      success: () => {
        message.success('Password Changed!', DURATION_TIMER);
      },
      error: () => {
        message.error('Submit failed', DURATION_TIMER);
      }
    },
    formdata: payload
  };
};

const getNecessities = (params?: any) => ({
  type: GET_NECESSITIES,
  endpoints: [
    { name: 'tenant_config', url: '/v4/dash/tenant_config' },
    { name: 'audiences', url: '/v4/dash/simple/audiences' },
    { name: 'tags', url: '/v4/dash/simple/tags?size=1000' },
    { name: 'categories', url: '/v4/dash/categories' },
    { name: 'labels', url: '/v4/dash/labels' },
    { name: 'user_tenants', url: '/v4/dash/user_oauth_applications' }
  ],
  params
});

const getNecessitiesSuccess = result => ({
  type: GET_NECESSITIES_SUCCESS,
  ...result
});

const getNecessitiesError = error => ({
  type: GET_NECESSITIES_FAIL,
  error
});

const changeAppLocale = locale => ({
  type: CHANGE_APP_LOCALE,
  locale
});

const getCustomerPendingRequest = payload => {
  return {
    type: GET_CUSTOMER_PENDING_REQUEST,
    endpoint: `/v4/dash/customer_requests`,
    method: 'get',
    types: [
      GET_CUSTOMER_PENDING_REQUEST,
      GET_CUSTOMER_PENDING_REQUEST_SUCCESS,
      GET_CUSTOMER_PENDING_REQUEST_FAIL
    ],
    params: payload.params
  };
};

const getCustomerPendingRequestFail = payload => {
  return {
    type: GET_CUSTOMER_PENDING_REQUEST_FAIL,
    payload
  };
};

const getCustomerPendingRequestSuccess = payload => {
  return {
    type: GET_CUSTOMER_PENDING_REQUEST_SUCCESS,
    payload
  };
};

const getCampaignPendingRequest = payload => {
  return {
    type: GET_CAMPAIGN_PENDING_REQUEST,
    endpoint: `/v4/dash/campaign_requests`,
    method: 'get',
    types: [
      GET_CAMPAIGN_PENDING_REQUEST,
      GET_CAMPAIGN_PENDING_REQUEST_SUCCESS,
      GET_CAMPAIGN_PENDING_REQUEST_FAIL
    ],
    params: payload.params
  };
};

const getCampaignPendingRequestFail = payload => {
  return {
    type: GET_CAMPAIGN_PENDING_REQUEST_FAIL,
    payload
  };
};

const getCampaignPendingRequestSuccess = payload => {
  return {
    type: GET_CAMPAIGN_PENDING_REQUEST_SUCCESS,
    payload
  };
};

const getRewardPendingRequest = payload => {
  return {
    type: GET_REWARD_PENDING_REQUEST,
    endpoint: `/v4/dash/reward_requests`,
    method: 'get',
    types: [
      GET_REWARD_PENDING_REQUEST,
      GET_REWARD_PENDING_REQUEST_SUCCESS,
      GET_REWARD_PENDING_REQUEST_FAIL
    ],
    params: payload.params
  };
};

const getRewardPendingRequestFail = payload => {
  return {
    type: GET_REWARD_PENDING_REQUEST_FAIL,
    payload
  };
};

const getRewardPendingRequestSuccess = payload => {
  return {
    type: GET_REWARD_PENDING_REQUEST_SUCCESS,
    payload
  };
};

const getRulePendingRequest = payload => {
  return {
    type: GET_RULE_PENDING_REQUEST,
    endpoint: `/v4/dash/rule_requests`,
    method: 'get',
    types: [
      GET_RULE_PENDING_REQUEST,
      GET_RULE_PENDING_REQUEST_SUCCESS,
      GET_RULE_PENDING_REQUEST_FAIL
    ],
    params: payload.params
  };
};

const getRulePendingRequestFail = payload => {
  return {
    type: GET_RULE_PENDING_REQUEST_FAIL,
    payload
  };
};

const getRulePendingRequestSuccess = payload => {
  return {
    type: GET_RULE_PENDING_REQUEST_SUCCESS,
    payload
  };
};

export {
  getNecessities,
  getNecessitiesSuccess,
  getNecessitiesError,
  changeAppLocale,
  getCustomerPendingRequest,
  getCustomerPendingRequestSuccess,
  getCustomerPendingRequestFail,
  getCampaignPendingRequest,
  getCampaignPendingRequestSuccess,
  getCampaignPendingRequestFail,
  getRewardPendingRequest,
  getRewardPendingRequestSuccess,
  getRewardPendingRequestFail,
  getRulePendingRequest,
  getRulePendingRequestSuccess,
  getRulePendingRequestFail
};
