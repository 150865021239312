import { message } from 'antd';
import axios from 'axios';
import _get from 'lodash/get';

import { userSignOut } from 'containers/App/actions';

import { API_ROOT, IS_PF } from '../appConstants';
import { browserHistory, store } from '../store';

const API = axios.create({
  baseURL: API_ROOT,
  headers: {
    'Content-Type': 'application/json'
  }
});

API.interceptors.request.use(
  config => {
    // @ts-ignore
    if (config.baseURL === API_ROOT && !config.external && !config.headers.Authorization) {
      config.headers.Accept = `*/*`;
      const token = _get(store.getState(), 'global.authUser.bearer_token', null);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

API.interceptors.response.use(
  response => response,
  error => {
    // console.log here will help to find not mocked requests in tests
    // which can cause tests crashes
    if (error?.response?.status === 404) {
      console.error(error);
      return Promise.reject(error);
    }
    if (error?.response?.status === 401) {
      store.dispatch(userSignOut(browserHistory, { urlParams: { error: 401 } }));
      if (error?.response?.data?.message) {
        message.info(error?.response?.data?.message);
      } else {
        message.info({
          content: 'You have been logged out due to an invalid session',
          key: 'invalid_session'
        });
      }
      if (IS_PF === '1') {
        if (document.querySelectorAll('.placeholder').length) window.location.reload();
      } else {
        if (window.location.pathname !== '/signin') {
          browserHistory.push('/signin?error=401');
          window.location.reload();
        }
      }
    } else {
      return Promise.reject(error);
    }
  }
);

/**
 * Currently have to download the file this way because the axios response does not trigger the native file download alert
 */
export const downloadFile = (blobPart: BlobPart, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blobPart]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => window.URL.revokeObjectURL(url), 0);
};

export default API;
