import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import Label from 'components/Text/Label';

export const LabelCol = styled(Col)`
  padding: 1rem;
  font-weight: 500;
  text-align: right;
  @media (max-width: 768px) {
    text-align: left;
  }
`;
export const ContentCol = styled(Col)`
  padding: 1rem;
`;
export const InputPaddingWrapper = styled(Row)`
  &:not(:last-of-type) ${ContentCol} {
    border-bottom: 1px solid #e7e7e7;
  }
`;

const RenderInGrid = ({
  description,
  content,
  hasContentChanged
}: {
  description: string;
  content: any;
  hasContentChanged?: any;
}) => {
  const testLabel = description.replace(/\s/g, '_').replace(':', '').toLowerCase();
  const style = hasContentChanged ? { backgroundColor: '#FF7F00', margin: '2px 0' } : {};

  return (
    <InputPaddingWrapper data-testid={testLabel}>
      <LabelCol xs={24} md={6} style={style}>
        <Label bold dark>
          {description}
        </Label>
      </LabelCol>
      <ContentCol xs={24} md={18}>
        {content}
      </ContentCol>
    </InputPaddingWrapper>
  );
};

export default RenderInGrid;
