export const BDO_TRANSACTION_CODE = {
  'MNLRDMP-99': 'Manual Redemption (Negative)',
  'RDMPCSA-99': 'Manual Redemption (Credit to CASA) (Negative)',
  'RDMPPRD-99': 'Manual Redemption – Promo Related (Negative)',
  'UPDATED BL-91': 'Updated host balance (Positive)',
  'UPDTDBL-99': 'Updated host balance (Negative)',
  'RECNADJ-91': 'Positive Reconciliation Adjustment',
  'RECNADJ-99': 'Negative Reconciliation Adjustment',
  'BDOTRSF-91': 'BDO Points Transfer(Positive)',
  'BDOTRSF-99': 'BDO Points Transfer(Negative)',
  'MCGCOLL-91': 'Merged/Collapsed CIF(Positive)',
  'MCGCOLL-99': 'Merged/Collapsed CIF(Negative)',
  'MCGREIN-91': 'BDO Manually Reinstated Forfeited Points(Positive)',
  'MCGREIN-99': 'BDO Manually Reinstated Forfeited Points, Reversal(Negative)',
  'BDORFEE-91': 'Card Replacement Fee – Reversal (Positive)',
  'BDORFEE-99': 'Card Replacement Fee (Negative)',
  'BBGREGD-91': 'Regular Total Monthly Earned Points-Dollar(Positive)',
  'BBGREGD-99': 'Regular Total Monthly Earned Points-Dollar(Negative)',
  'BBGREGP-91': 'Regular Total Monthly Earned Points-Peso(Positive)',
  'BBGREGP-99': 'Regular Total Monthly Earned Points-Peso(Negative)',
  'CAT-BDORW1': 'BDO Credit Card push points to Primary(Positive)',
  'CAT-BDORW2': 'BDO Credit Card push points to 3rd party(Positive)',
  'GFTRDMP-99': 'Redemption thru GiftAway (Negative)',
  'GFTRDMP-91': 'Redemption thru GiftAway – Reversal (Positive)',
  'PAYRDMP-99': 'Redemption thru BDOPay (Negative)',
  'PAYRDMP-91': 'Redemption thru BDOPay – Reversal (Positive)',
  'POSRDMP-99': 'POS Redemption (Negative)',
  'POSRDMP-91': 'POS Redemption – Reversal (Positive)',
  'PRXTRSF-91': 'PRX Points Transfer (Positive)',
  'PRXTRSF-99': 'PRX Points Transfer (Negative)',
  'PXMRDMP-99': 'Perx Manual Redemption via dashboard (Negative)',
  'PXMRDMP-91': 'Perx Manual Redemption via dashboard (Reversal)',
  'TRTSRDM-99': 'BDO Treats Redemptions',
  'MCGIN24-91': 'Forfeited Points of Card Inactive for 24mos,Reversal (Positive)',
  'MCGIN24-99': 'Forfeited Points of Card Inactive for 24months (Negative)',
  'MCI_EXP-91': 'Forfeiture of Expired Points, Reversal  (Positive)',
  'MCI_EXP-99': 'Forfeiture of Expired Points (Negative)',
  'BDOINCTR-1': 'BDO Manual Adjustment - Credit (incl in tiering) - Positive',
  'BDOINCTR-9': 'BDO Manual Adjustment - Debit (incl in tiering) - Negative',
  'BDOEXCTR-1': 'BDO Manual Adjustment - Credit (excl from tiering) - Positive',
  'BDOEXCTR-9': 'BDO Manual Adjustment - Debit (excl from tiering) - Negative',
  'MCGDECD-91': 'Deceased Clients - Credit - Positive',
  'MCGDECD-99': 'Deceased Clients - Debit - Negative',
  'BDOPHL01-1': 'Home Loan 10x Pts - Positive',
  'BDOPHL01-9': 'Home Loan 10x Pts - Negative',
  'BDOPHL02-1': '5x Pts Home Loan - Positive',
  'BDOPHL02-9': '5x Pts Home Loan - Negative',
  'BDOREGIN-1': 'BDO Manual Adj of RegTrans-Credit(incl in tiering) - Positive',
  'BDOREGIN-9': 'BDO Manual Adj of RegTrans-Debit(incl in tiering) - Negative',
  'CBGPRODL-1': 'BDO Online Download and Login eRaffle Promo (Positive)',
  'CBGPRODL-9': 'BDO Online Download and Login eRaffle Promo (Negative)',
  'CBGPRSP-91': 'Bonus Points Promo for Scan to Pay Transactions - Positive',
  'CBGPRSP-99': 'Bonus Points Promo for Scan to Pay Transactions -Negative',
  'CBGPRPS2-1':
    '50 Bonus Pts for min. of P1K Scan to Pay trxn from Aug 15 to Oct 31 2024 (Positive)',
  'CBGPRPS2-9':
    '50 Bonus Pts for min. of P1K Scan to Pay trxn from Aug 15 to Oct 31 2024 (Negative)',
  'CBGPRBPW-1': 'Welcome Bonus Points for new BDO Pay signups (Positive)',
  'CBGPRBPW-9': 'Welcome Bonus Points for new BDO Pay signups (Negative)',
  'REMPRCTA-1': 'Bonus Points Promo for Remittance (Positive)',
  'REMPRCTA-9': 'Bonus Points Promo for Remittance (Negative)',
  'TIGPRMMF-1': 'Bonus Points Promo for new PMMF and DMMF placement (Positive)',
  'TIGPRMMF-9': 'Bonus Points Promo for new PMMF and DMMF placement (Negative)',
  'CBGPRDRC-1': '50 Bonus Points for Debit Card Use (Lapsers) - Positive',
  'CBGPRDRC-9': '50 Bonus Points for Debit Card Use (Lapsers) - Negative'
};

export const dateFormat = 'MM-DD-YYYY';
export const dateTimeFormat = 'MM-DD-YYYY HH:mm';
export const serverDateFormat = 'DD-MM-YYYY';
export const serverTimeFormat = 'HH:mm:ss';
