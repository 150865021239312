import React, { memo, useState } from 'react';
import { Card } from 'antd';
import { Col, Row } from 'antd';
import { TableProps } from 'antd/lib/table';
import styled from 'styled-components';
import { TableMainText, TableSecondaryText } from 'styles';

import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import CampaignFilter from 'containers/Analytics/components/CampaignFilter';
import { TableGraph } from 'containers/Analytics/pages/graphs';
import HeatMapGraph from 'containers/Analytics/pages/graphs/HeatMapGraph';
import { useTenant } from 'hooks';

import MetricsCard from '../../components/MetricsCard';
import { CardsRow } from '../../styles';
import {
  CampaignTableGraph,
  CampaignsEngagementGraph,
  CampaignVSGraph,
  CampaignStackedHistogram
} from '../graphs';
import * as S from './styles';

const Stamps = () => {
  const { name: tenantName } = useTenant();
  const [filterParams, setFilterParams] = useState({});

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CampaignFilter onChange={setFilterParams} campaignType={'stamp'} />
        </Col>
        <Col xs={24}>
          <CampaignsEngagementGraph
            identifier={[
              'campaign_flat_stamp_breakdown_by_date_engagement',
              'campaign_flat_stamp_breakdown_by_date_view',
              'campaign_flat_stamp_breakdown_by_date_completion'
            ]}
            filterParams={filterParams}
          />
        </Col>
      </Row>
      {/* <Card style={{ margin: '20px 0px -40px 0px' }}>
        <CardHeader>Key Metrics</CardHeader>
      </Card>
      <CardsRow   gutter={[0, 16]}>
        <MetricsCard
          identifier="campaign_stamp_customer_engagement"
          description="CUSTOMER ENGAGEMENTS"
          helpText="Average revenue each reward is generating for you. Total revenue / total number of paid rewards."
        />
        <MetricsCard
          identifier="campaign_stamp_top_line_growth"
          description="TOP LINE GROWTH"
          helpText="Average revenue each reward is generating for you. Total revenue / total number of paid rewards."
        />
      </CardsRow>
      <Row   gutter={[16, 16]}>
        <Cards md={24}>
          <StampsBI filterParams={filterParams} />
        </Cards>
      </Row>*/}

      <S.CardHeader style={{ marginLeft: '26px', marginBottom: '-64px', marginTop: '40px' }}>
        Summary
      </S.CardHeader>
      <CardsRow gutter={[0, 16]} style={{ paddingTop: '70px' }}>
        <MetricsCard
          identifier="campaign_flat_stamp_total_active_campaign"
          description="TOTAL ACTIVE CAMPAIGNS"
          helpText="Total number of current active campaigns"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_stamp_total_unique_view"
          description="TOTAL UNIQUE VIEWS"
          helpText="Number of unique users that viewed your campaign"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_stamp_engagement_rate"
          description="ENGAGEMENT RATE"
          helpText="Percentage of audience reached who completed the campaign"
          filterParams={filterParams}
        />
        <MetricsCard
          identifier="campaign_flat_stamp_active_user"
          description="USERS WHO REDEEMED"
          helpText="Campaign - Stamp Active User"
          filterParams={filterParams}
        />
      </CardsRow>

      <GridRenderer perRow={1} gutter={16}>
        <S.Cards md={24}>
          <StampsTopByEngagementRateBI filterParams={filterParams} />
        </S.Cards>
      </GridRenderer>
      <S.SectionHeader>Customer Insights</S.SectionHeader>
      <GridRenderer perRow={1} gutter={16}>
        <CampaignVSGraph
          identifier="campaign_flat_stamp_new_vs_returning_user"
          title="New vs Returning Users"
          filterParams={filterParams}
          helpText="Percentages of first time users vs returning users"
        />
      </GridRenderer>
      {tenantName !== TENANTS.RAZER && (
        <GridRenderer perRow={2} gutter={16}>
          <HeatMapGraph
            identifier="campaign_flat_stamp_popular_redemption_day"
            title="Popular Redemption Days"
            filterParams={filterParams}
            helpText="Which specific day and timing users have been issued the most vouchers. The level of vouchers issued is indicated by the intensity of the colour spectrum."
          />
          <TableGraph
            identifier="campaign_flat_stamp_flow"
            title="Reward Redemption Rate"
            filterParams={filterParams}
            n={5}
            tableProps={{
              rowKey: r => r[1],
              columns: [
                {
                  title: <FirstCell>Campaign Name</FirstCell>,
                  key: 'campaign_name',
                  dataIndex: '[1]',
                  render: value => <FirstCell>{value ? value : 'Unknown'}</FirstCell>,
                  width: 100
                },
                {
                  title: 'Redemption Rate',
                  key: 'redemption_rate',
                  dataIndex: '[2]',
                  width: 200,
                  render: value => <FirstCell>{value ? value : 'Unknown'}</FirstCell>
                }
              ]
            }}
          />
          <CampaignStackedHistogram
            identifier="campaign_flat_stamp_age"
            title="Age"
            filterParams={filterParams}
            legend1={'Stamp cards issued'}
            legend2={'Stamp cards opened'}
          />
          <CampaignStackedHistogram
            identifier="campaign_flat_stamp_gender"
            title="Gender"
            filterParams={filterParams}
            legend1={'Stamp cards issued'}
            legend2={'Stamp cards opened'}
          />
          <StampsStateBI filterParams={filterParams} />
          <StampsClusterBI filterParams={filterParams} />
        </GridRenderer>
      )}
    </>
  );
};

const StampsStateBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    // scroll: { x: 'scroll' },
    columns: [
      {
        title: 'State Name',
        key: 'primary_cluster_state',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Stamp Cards Issued"
            tooltip="Difference between revenue from the Stamp campaign and the cost of issued rewards"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Stamp Cards Completed"
            tooltip="Percentage of Stamp campaigns profit out of cost of issued rewards"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_stamp_state"
        title="Location - State"
        minHeight="320px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

const StampsClusterBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    columns: [
      {
        title: 'Cluster Name',
        key: 'primary_cluster',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Engagements"
            tooltip="Difference between revenue from the Stamp campaign and the cost of issued rewards"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completions"
            tooltip="Percentage of Stamp campaigns profit out of cost of issued rewards"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_stamp_cluster"
        title="Location - Cluster"
        minHeight="320px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

const StampsTopByEngagementRateBI = ({ filterParams }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    scroll: { x: 1500 },
    columns: [
      {
        title: 'Campaign Name',
        key: 'campaign_name',
        dataIndex: '[2]',
        render: (text, record) => {
          return (
            <>
              <TableMainText>{record[2]}</TableMainText>
              <TableSecondaryText>ID: {record[1]}</TableSecondaryText>
            </>
          );
        }
      },
      {
        title: (
          <TableHeader
            title="Total API Calls"
            tooltip="Total number of times campaign/id was called for campaign "
          />
        ),
        key: 'total_views',
        dataIndex: '[3]',
        render: (text, record) => <span>{record[3]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Unique users who got stamps"
            tooltip="Total number of users who have enrolled in the campaign"
          />
        ),
        key: 'user_enrolled',
        dataIndex: '[4]',
        render: (text, record) => <span>{record[4]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Unique users who viewed stamps"
            tooltip="No. of users who have called campaign/id "
          />
        ),
        key: 'unique_view',
        dataIndex: '[5]',
        render: (text, record) => <span>{record[5]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Total number of stamp cards issued"
            tooltip="Total number of stamp cards issued"
          />
        ),
        key: 'stamp_cards_issued',
        dataIndex: '[6]',
        render: (text, record) => <span>{record[6]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Total number of stamp cards completed"
            tooltip="Total number of stamp cards completed"
          />
        ),
        key: 'stamp_cards_completed',
        dataIndex: '[7]',
        render: (text, record) => <span>{record[7]?.toLocaleString()}</span>
      },
      {
        title: <TableHeader title="Stamps Issued" tooltip="Total number of stamps issued" />,
        key: 'stamp_issued',
        dataIndex: '[8]',
        render: (text, record) => <span>{record[8]?.toLocaleString()}</span>
      },
      {
        title: <TableHeader title="Stamps Redeemed" tooltip="Total number of stamps redeemed" />,
        key: 'stamp_redeemed',
        dataIndex: '[9]',
        render: (text, record) => <span>{record[9]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completion rate"
            tooltip="Total number of stamp cards completed/ total number of stamp cards issued "
          />
        ),
        key: 'completion_rate',
        dataIndex: '[10]',
        render: (text, record) => <span>{record[10]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_flat_stamp_top_campaign_by_performance"
        title="Performance"
        tableProps={tableProps}
        pagination={true}
        scrollX={true}
        filterParams={filterParams}
      />
    </>
  );
};

interface TableHeaderProps {
  title: string;
  tooltip?: string;
}

const TableHeader = ({ title, tooltip }: TableHeaderProps) => {
  return <>{title}</>;
};

const FirstCell = styled.div`
  padding-left: 16px;
`;

export default memo(Stamps);
